import React, { useState, useEffect } from 'react';
//import ProfileIssue from './ProfileIssue';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripLines } from '@fortawesome/free-solid-svg-icons'
import { Card, Button, Grid, CardContent, CardActions, Fab, TextField, Accordion, AccordionSummary, AccordionDetails, Modal, Backdrop, Fade, Select, Menu, MenuItem } from '@material-ui/core';
import {  EDITOR_PAGE_URL } from "../../constants"
import LegislationList from '../../components/legislation/legislationList';
import CreateIcon from '@material-ui/icons/Create';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { supabase } from "../../actions/shared/api";
import { arrayMove } from 'react-sortable-hoc';
import { IconButton } from '@material-ui/core';


const DragHandle = sortableHandle(() => <FontAwesomeIcon className="text-primary" icon={faGripLines} />);

const baseUrl = 'writelegislation.com';

const copyToClipboard = async (text) => {
  try {
      await navigator.clipboard.writeText(text);
      console.log('Link copied!:', text);
      alert('Link copied!: ' + text);
  } catch (err) {
      console.error('Error copying link: ', err);
      alert('Error copying link: ' + err);
  }
} 



const SortableItem = SortableElement(({issue, deleteIssue, postIssue, editing, items, updateStatus, legislationList, loadLegislation, contest, updateIssue}) => {
    
  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState(issue.title);
  const [status, setStatus] = useState(issue.status);
  const [description, setDescription] = useState(issue.description);
  const [legislation, setLegislation] = useState(issue.legislation);
  const [open, setOpen] = useState(false);
  const [taskInput, setTaskInput] = useState('');
  const [tasks, setTasks] = useState([]);
  const [fileInput, setFileInput] = useState('');
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [noteInput, setNoteInput] = useState('');
  const [notes, setNotes] = useState([]);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };
  
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleLegislationChange = (event) => {
    setLegislation(event.target.value);
  };

  const handleTaskInputChange = (event) => {
    setTaskInput(event.target.value);
  };

  const handleNoteInputChange = (event) => {
    setNoteInput(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveEdits = async () => {
    const updateData = { title, description, legislation, status: status };
    const { data, error } = await supabase
      .from('issues')
      .update(updateData)
      .eq('id', issue.id);
  
    if (error) {
      console.error('Failed to update issue: ', error);
    } else {
      setEditMode(false);
    }
  };

  const [legislationData, setLegislationData] = useState([]); 

  useEffect(() => {
    const fetchLegislation = async () => {
      const legislation = await loadLegislation(issue.id);
      setLegislationData(legislation);
    };

    fetchLegislation();
  }, [issue.id, loadLegislation]);

  const handleAddTask = async () => {
    if (taskInput.trim()) {
      const newTask = {
        id: Date.now(),
        text: taskInput,
        contest: issue.id // Assuming tasks need to know which contest/issue they belong to
      };
  
      // Save new task to Supabase
      const { data, error } = await supabase
        .from('tasks')
        .insert([newTask]);
  
      if (error) {
        console.error('Failed to add task: ', error);
      } else {
        const updatedTasks = [...tasks, newTask];
        setTasks(updatedTasks);
        setTaskInput('');
      }
    }
  };
  
  const loadTasks = async (contestId) => {
    try {
      const { data } = await supabase
        .from("tasks")
        .select("text, id")
        .eq('contest', contestId);
    
      return data;
    } catch (error) {
      console.error("Error loading tasks:", error);
      return [];
    }
  };

  useEffect(() => {
    (async () => {
      const fetchedTasks = await loadTasks(issue.id);
      setTasks(fetchedTasks);
    })();
  }, [issue.id]);  

  useEffect(() => {
 }, [tasks]);

 const deleteTask = async (taskId) => {
  try {
    // Delete the task from Supabase
    const { data, error } = await supabase
      .from('tasks')
      .delete()
      .eq('id', taskId);

    if (error) {
      throw error;
    }
    setTasks(prevTasks => prevTasks.filter(task => task.id !== taskId));
  } catch (error) {
    console.error('Failed to delete task:', error);
  }
};

const BUCKET_NAME = 'files';

const uploadFileToStorage = async (file, issueId) => {
  try {
    const timestamp = new Date().toISOString();
    const filePath = `uploads/issue_${issueId}/${timestamp}-${file.name}`;
    const { error } = await supabase.storage.from(BUCKET_NAME).upload(filePath, file);

    if (error) {
      throw error;
    }

    return {
      name: file.name,
      mimetype: file.type,
      size: file.size,
      path: filePath
    };
  } catch (err) {
    console.error("Error uploading file:", err);
    return null;
  }
};

const insertFileMetadata = async (fileData) => {
  try {
    const { error } = await supabase.from('files').insert([fileData]);

    if (error) {
      throw error;
    }
  } catch (err) {
    console.error("Error storing file metadata:", err);
  }
};

const loadFilesFromStorage = async (issueId) => {
  try {
    const { data, error } = await supabase.storage.from(BUCKET_NAME).list(`uploads/issue_${issueId}/`);
    
    if (error) {
      throw error;
    }

    console.log("Fetched files:", data);
    return data;
  } catch (err) {
    console.error("Error loading files:", err);
    return [];
  }
};

const handleAddFile = async (event) => {
  if (!event.target.files || event.target.files.length === 0) return;

  const file = event.target.files[0];
  const fileData = await uploadFileToStorage(file, issue.id);

  if (fileData) {
    await insertFileMetadata(fileData);
    const fetchedFiles = await loadFilesFromStorage(issue.id);
    setFiles(fetchedFiles);
  }
};

const deleteFileFromStorage = async (file) => {
  try {
    const { error } = await supabase.storage.from(BUCKET_NAME).remove([file.path]);
    
    if (error) {
      console.error("Error deleting file:", error);
    }
  } catch (err) {
    console.error('Failed to delete file from storage:', err);
  }
};

const deleteFile = async (fileId) => {
  console.log("Attempting to delete file with ID:", fileId);
  const file = files.find(f => f.id === fileId);
  if (!file) return;

  await deleteFileFromStorage(file);

  const fetchedFiles = await loadFilesFromStorage(issue.id);
  setFiles(fetchedFiles);
};

useEffect(() => {
  const fetchFiles = async () => {
    try {
      const fetchedFiles = await loadFilesFromStorage(issue.id);
      setFiles(fetchedFiles);
    } catch (err) {
      console.error("Error fetching files:", err);
    }
  };

  fetchFiles();
}, [issue.id]);

const handleAddNote = async () => {
  if (noteInput.trim()) {
    const newNote = {
      id: Date.now(),
      text: noteInput,
      contest: issue.id
    };
    const { data, error } = await supabase
      .from('notes')
      .insert([newNote]);

    if (error) {
      console.error('Failed to add note: ', error);
    } else {
      const updatedNotes = [...notes, newNote];
      setNotes(updatedNotes);
      setNoteInput('');
    }
  }
};

const loadNotes = async (contestId) => {
  try {
    const { data } = await supabase
      .from("notes")
      .select("text, id")
      .eq('contest', contestId);
  
    return data;
  } catch (error) {
    console.error("Error loading notes:", error);
    return [];
  }
};

useEffect(() => {
  (async () => {
    const fetchedNotes = await loadNotes(issue.id);
    setNotes(fetchedNotes);
  })();
}, [issue.id]);  

useEffect(() => {
}, [notes]);

const deleteNote = async (noteId) => {
try {
  const { data, error } = await supabase
    .from('notes')
    .delete()
    .eq('id', noteId);

  if (error) {
    throw error;
  }
  setNotes(prevNotes => prevNotes.filter(note => note.id !== noteId));
} catch (error) {
  console.error('Failed to delete note:', error);
}
};
  
  const toggle = () => {
    if (editMode) {
      saveEdits();
    }
    setEditMode(!editMode);
  };
  
  function getStatusColor(status) {
    switch (status) {
      case 'new':
        return '#FFD43E';
      case 'research':
        return '#5FAAB6';
      case 'funding':
        return '#78FBBE';
      case 'writing':
        return '#FBBE78';
      case 'submitting':
        return '#4974A5';
      case 'archived':
        return '#8B0000';
        default:
        return '#FEF4D2';
    }
  };
  
  return (
          <div borderRight={4} className="issueCard mb-4 card col-6" style={{ borderRadius: '30px' }}>
            <div className="title-status-raised-container">
              <div className="dragHandleContainer p-2">
                {editing? <DragHandle /> : null}
              </div>
            
            {/* Title */}
              <div className="issueTitleContainer p-3">
                <h5 className="lato-font">
                  Title:{' '}
                  {editMode ? (
                    <TextField
                      value={title}
                      onChange={handleTitleChange}
                      onBlur={saveEdits}
                    />
                  ) : (
                    <span>{title}</span>
                  )}
                </h5>
              </div>
            
            {/* Status */}
            <div className="issueStatusContainer">
              <p className="lato-font">
                 | Status:{' '}
                  {editMode ? (
                    <Select
                      value={status}
                      onChange={handleStatusChange}
                      onBlur={saveEdits}
                    >
                      <MenuItem value="draft">draft</MenuItem>
                      { /* <MenuItem value="published">published</MenuItem> */}
                      <MenuItem value="new">new</MenuItem>
                      <MenuItem value="research">research</MenuItem>
                      <MenuItem value="funding">funding</MenuItem>
                      <MenuItem value="writing">writing</MenuItem>
                      <MenuItem value="submitting">submitting</MenuItem>
                      <MenuItem value="archived">archive</MenuItem>
                    </Select>
                  ) : (
                    <span>{status}</span>
                  )}
                </p>
            </div>
          {/* Raised! */}
          <div className="issueRaisedContainer">
            <p className="lato-font">Raised!: {issue.raised}</p>
          </div>
        </div>
            <div className="middleSection" style={{ backgroundColor: getStatusColor(issue.status) , width: '100%' , margin: 0 }}>
            <div borderRight={4} className= "detailsSection p-3 mb-4 card col-6" style={{ backgroundColor: '#352F19', color: 'white' , width:'90%'}}>
                <div className="lato-font" style={{marginBottom: "3%"}}>
                <span style={{fontWeight: "bold", marginRight: "1%"}}>Description:</span>{' '}
                  {editMode ? (
                    <TextField
                      value={description}
                      onChange={handleDescriptionChange}
                      onBlur={saveEdits}
                      multiline
                      rows={4}
                      rowsMax={8}
                      style={{ width: '100%' }}
                    />
                  ) : (
                    <span>{description}</span>
                  )}
                </div>
                <div className="lato-font">
                <span style={{fontWeight: "bold", marginRight: "1%"}}>Legislation Needed:</span>{' '}
                  {editMode ? (
                    <TextField
                      value={legislation}
                      onChange={handleLegislationChange}
                      onBlur={saveEdits}
                      multiline
                      rows={4}
                      rowsMax={8}
                      style={{ width: '100%' }}
                    />
                  ) : (
                    <span>{legislation}</span>
                  )}
                </div>
                <Fab className="editFab" size="small" color="#FFFFFF" aria-label="edit" onClick={toggle}>
                  <EditIcon />
                </Fab>
                <div> 
                  <button className="copyButton" onClick={() => copyToClipboard(`${baseUrl}/contest/${issue.id}`)}>🔗</button>
                </div>
              </div>
                  <div className="addLegislationFab">
                    <CardActions>
                      <Link to={EDITOR_PAGE_URL + "/legislation?contest=" + issue.id} >
                          <Fab size="small" color="primary" aria-label="add">
                            <AddIcon />
                          </Fab>
                      </Link>
                    </CardActions>
                  </div>
                <a className= "viewIssueButton" href={"/contest/" + issue.id}><button className="turq-primary-button mr-2">View Issue Page</button></a>
              </div>
            <div borderRight={4} className="addStuffSection p-0" style={{ color: 'white' , width: '100%' , margin: 0 }}>
            {/* {issue.status === 'draft'? <button className="turq-secondary-button" style={{marginLeft: '20px'}} onClick={() => postIssue(issue.id)}>Post Issue</button> : null} */}
            <div className="legislationSection">
              <CardContent>
                <h7 variant="h4" className="text-primary" component="h2">
                  Legislation Written For This Issue
                </h7>
                <div>
                  {legislationData.map((legislation, index) => (
                    <div key={index}>
                      <a href={`/editor/legislation?contest=${issue.id}&legislationID=${legislation.id}`}>{legislation.title}</a>
                    </div>
                  ))}
                </div>
              </CardContent>
            </div>
            <Accordion className="taskAccordion" style={{ display: 'block', width: '100%' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h4>Task List</h4>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '45%', backgroundColor: '#FFFFFF', padding: '0.5em' }}>
                        <TextField
                            id="outlined-basic"
                            value={taskInput}
                            onChange={handleTaskInputChange}
                            variant="outlined"
                            InputProps={{
                              style: { height: "2em" }
                            }}
                            style={{ flex: 1 }}
                        />
                        <Fab size="small" color="tertiary" aria-label="add-task" onClick={handleAddTask} style={{ width: '12%', height: '10%', marginLeft: '5%' }}>
                            <AddIcon fontSize="small" />
                        </Fab>
                    </div>
                    <div className="taskContainer" style={{ width: '50%', padding: '0.5em' }}>
                        {tasks.map(task => (
                            <div key={task.id} className="task">
                                <span>{task.text}</span>
                                <span>{task.content}</span>
                                <IconButton size="small" color="secondary" aria-label="delete" onClick={() => deleteTask(task.id)} style={{ width: '15%', height: '5%', maxWidth: '2em', maxHeight: '2em', margin: '5%' }}>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </div>
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion className="fileAccordion" style={{ display: 'block', width: '100%' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h4>File Upload</h4>
                </AccordionSummary>
                <AccordionDetails>
                    <input
                        type="file"
                        id="fileInput"
                        onChange={handleAddFile}
                        multiple
                    />
                    {files?.length > 0 && (
                        <ul>
                            {files.map(fileMeta => {
                                // Move these lines inside the map function
                                const fileFullPath = `uploads/issue_${issue.id}/${fileMeta.name}`;
                                const fileURLObject = supabase.storage.from(BUCKET_NAME).getPublicUrl(fileFullPath);
                                const fileURL = fileURLObject.publicURL;                                                             

                                return (
                                    <li key={fileMeta.id}>
                                        <div id="fileMetaInfo">
                                            {fileMeta.path}
                                        </div>
                                        <a href={fileURL || '#'} target="_blank" rel="noopener noreferrer">
                                          {fileMeta && typeof fileMeta.name === "string" ? fileMeta.name.split("/")[2] || fileMeta.name : "Unknown File"}
                                        </a>
                                        <IconButton 
                                          size="small" 
                                          color="secondary" 
                                          aria-label="delete" 
                                          onClick={() => deleteFile(fileMeta.id)}
                                          style={{ 
                                              width: '24px', 
                                              height: '18px', 
                                              margin: '10px'
                                          }}>
                                          <DeleteIcon fontSize="small" />
                                      </IconButton>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </AccordionDetails>
                </Accordion>
                <Accordion className="noteAccordion" style={{ display: 'block', width: '100%', borderRadius: '0 0 30px 30px' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h4>Issue Notes</h4>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '45%', backgroundColor: '#FFFFFF', padding: '0.5em' }}>
                        <TextField
                            id="outlined-multiline"
                            multiline
                            rows={6}
                            value={noteInput}
                            onChange={handleNoteInputChange}
                            InputProps={{
                              style: { height: "2em" }
                            }}
                            style={{ flex: 1 }}
                        />
                        <Fab size="small" color="tertiary" aria-label="add-task" onClick={handleAddNote} style={{ width: '12%', height: '10%', marginLeft: '5%' }}>
                            <AddIcon fontSize="small" />
                        </Fab>
                    </div>
                    <div className="noteContainer" style={{ width: '50%', padding: '0.5em' }}>
                        {notes.map(note => (
                            <div key={note.id} className="note">
                                <span>{note.text}</span>
                                <span>{note.content}</span>
                                <IconButton size="small" color="secondary" aria-label="delete" onClick={() => deleteNote(note.id)} style={{ width: '15%', height: '5%', maxWidth: '2em', maxHeight: '2em', margin: '5%' }}>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </div>
                        ))}
                    </div>
                </AccordionDetails>
                </Accordion>
              </div>
        </div>
    )
});

const SortableList = SortableContainer(({items, deleteIssue, postIssue, editing, tasks, addTask, setTasks, handleAddTask, addFile, setFiles, handleAddFile, addNote, setNotes, handleAddNote,updateStatus, legislationList, loadLegislation, contest}) => {
  const [legislationData, setLegislationData] = useState([]);

  return (
    <span>
      {items.map((issue, index) => (
          <SortableItem 
          useDragHandle={true} 
          key={issue.id}
          index={index} 
          issue={issue}
          editing={editing} 
          deleteIssue={deleteIssue} 
          postIssue={postIssue} 
          addTask={addTask} 
          setTasks={setTasks} 
          handleAddTask={handleAddTask} 
          addFile={addFile} 
          setFiles={setFiles} 
          handleAddFile={handleAddFile}
          addNote={addNote} 
          setNotes={setNotes} 
          handleAddNote={handleAddNote}
          updateStatus={updateStatus} 
          legislationList={legislationList || []}
          loadLegislation={loadLegislation}
          contest={contest}
          legislationData={legislationData[index] || []}
          />
      ))}
    </span>
  );
});

const SortableIssuesList = (props) => {
  
  const [legislationList, setLegislationList] = useState([]);
  const [localIssues, setLocalIssues] = useState(() => {
    const savedIssues = JSON.parse(localStorage.getItem(`issuesOrder_${props.userId}`));
    return savedIssues || props.issues;
  });

  useEffect(() => {
    if (!localStorage.getItem(`issuesOrder_${props.userId}`)) {
      setLocalIssues(props.issues);
    }
  }, [props.issues, props.userId]);

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    const newIssues = arrayMove(localIssues, oldIndex, newIndex);
    setLocalIssues(newIssues);

    localStorage.setItem(`issuesOrder_${props.userId}`, JSON.stringify(newIssues));
  
    try {
      for (let i = 0; i < newIssues.length; i++) {
        const issue = newIssues[i];
        await supabase
          .from('issues')
          .update({ order: i })
          .eq('id', issue.id);
      }
  
      const { data, error } = await supabase
        .from('issues')
        .select('*')
        .order('order', { ascending: true });
      if (error) {
        console.error('Error fetching updated issues:', error);
      } else {
        setLocalIssues(data);
        localStorage.setItem(`issuesOrder_${props.userId}`, JSON.stringify(data));
      }
    } catch (error) {
      console.error('Error updating issue order:', error);
    }
  };  

useEffect(() => {
  const fetchIssues = async () => {
    try {
      const { data, error } = await supabase
        .from('issues')
        .select('*')
        .order('order', { ascending: true });
      if (error) {
        console.error('Error fetching issues:', error);
        return;
      }
      setLocalIssues(data);
    } catch (error) {
      console.error('Error fetching issues:', error);
    }
  };

  fetchIssues();
}, [props.userId]);


  const updateIssue = (id, updatedIssue) => {
    const updatedIssues = props.issues.map((issue) => {
      if (issue.id === id) {
        return { ...issue, ...updatedIssue };
      }
      return issue;
    });
    props.setIssues(updatedIssues);
  };  
  
  const loadLegislation = async (issueId) => {

      const { data, error } = await supabase
        .from("legislation")
        .select("title, id")
        .eq('contest', issueId);
    
      if (error) {
        console.error("Error fetching legislation: ", error);
        return error;
      }
      
      console.log("LD: ", data);
      return data;
    };  
  
    const { contest } = props;


    useEffect(() => {
      loadLegislation(contest);
    }, [contest]);
  
    return (
      <div className="p-4 container">
        <SortableList
          legislationList={legislationList}
          useDragHandle={true}
          axis="xy"
          distance={5}
          items={localIssues}
          onSortEnd={onSortEnd}
          editing={props.editing}
          updateIssue={updateIssue}
          deleteIssue={props.deleteIssue}
          postIssue={props.postIssue}
          updateStatus={props.updateStatus}
          loadLegislation={loadLegislation}
          contest={contest}
        />
      </div>
    );
  };

export default SortableIssuesList;
import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { supabase } from "../actions/shared/api";
import Layout from "../components/layout/layout";
import { updateContestStatus } from "../actions/contestActions";
import DismissableAlert from "../components/dismissableAlert";
import { isPastEndDate } from "../util/dateCompare";
import ContestSubmissionsCard from "../components/competition/contestSubmissionsCard";
//import ContestFundingCard from "../components/competition/contestFundingCard"
import ItemizedFundingCard from "../components/competition/itemizedFundingCard";
import ContestShareCard from "../components/competition/contestShareCard";
import ContestStateCard from "../components/competition/contestStateCard";
import CompetitionText from "../components/competition/competitionText";
import GoalRing from "../components/legislation/goalRing";
import Card from "@material-ui/core/Card";
import Link from "@material-ui/core/Link";
import { BASE_URL } from '../constants';

class ContestPage extends React.Component {
  
  constructor(props) {
    console.log(BASE_URL);
    super(props);
    this.state = {
      legislationList: [],
      contest: null,
    };
    this.handleStatusUpdate = this._handleStatusUpdate.bind(this);
  }

  _handleStatusUpdate(statusId) {
    const contestId = this.props.match.params.id;
    this.props.dispatch(updateContestStatus(contestId, statusId));
  }

  loadIssue = async () => {
    let issueID = '';
    // If this is being used as the confirmation page from a new issue posting we need to load that data
    if(window.location.href.split("/")[3] === 'issue_confirm') {
      issueID = parseInt(localStorage.getItem('postedIssue')); 
    } else {
      issueID = window.location.href.split("/")[4];
    }
    console.log(issueID);
    const { data, error } = await supabase
      .from("issues")
      .select("*")
      .match({ id:  issueID});

    if (error) {
      return error;
    }

    this.setState({ contest: data[0] });
  };

  loadLegislation = async () => {
    const { data, error } = await supabase
      .from("legislation")
      .select("*")
      .match({ contest: window.location.href.split("/")[4] });

    if (error) {
      return error;
    }

    this.setState({ ...this.state, legislationList: data });
  };

  componentDidMount() {
    this.loadIssue();
    this.loadLegislation();
  }

  render() {
    var contest = this.state.contest;
    var alert;

    if (contest) {
      var pastEndDate = isPastEndDate(contest.endDate);
      if (pastEndDate && !!contest.legislatureLink) {
        alert = (
          <DismissableAlert
            variant="turq"
            heading="This Issue Has Been Closed!"
          >
            <p className="mb-2">
              {" "}
              The legislation is making it's way through the legislature
              now!{" "}
            </p>
            <p className="my-0">
              {" "}
              Check it out here:{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={contest.legislatureLink}
              >
                {contest.legislatureLink}
              </a>
            </p>
          </DismissableAlert>
        );
      } else if (pastEndDate) {
        alert = (
          <DismissableAlert
            variant="turq"
            heading="This Issue Has Been Closed!"
          >
            <p className="mb-2">
              {" "}
              The legislation is still being selected
            </p>{" "}
          </DismissableAlert>
        );
      } else {
        alert = null;
      }
    }

    return (
      <div>
        {contest ? (
          <Grid
            container
            justify="center"
            direction="column"
            style={{ padding: 20 }}
            className="background-primary"
          >
            <Grid item>{alert}</Grid>
            <Grid container justify="center" spacing={5} className="background-secondary">
              <Grid item xs={12} md={8} className="background-secondary">
                <CompetitionText
                  title={contest.title}
                  prizes={contest.prize}
                  description={contest.description}
                  legislation={contest.legislation}
                  rules={contest.rules}
                  criteria={contest.criteria}
                  endDate={contest.endDate}
                  className="background-secondary"
                />
              </Grid>
              <Grid container item xs={12} md={4} style={{ padding: 34 }}>
                <Grid container item direction="column" spacing={5}>
                  <Grid container justify="center" align="center">
                    <Grid item>
                      <GoalRing currentFunding={contest.currentFunding} />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <ItemizedFundingCard
                      currentFunding={contest.currentFunding}
                      contestId={contest.id}
                    />
                  </Grid>
                  {contest?.author?.email === this.props.email ||
                  this.props.isAdmin ? (
                    <Grid item alignContent="center">
                      <Card style={{ padding: "25px", background: 'rgb(43, 48, 58)' }}>
                        <Link
                          href={`/editor/contest/${contest.id}`}
                          style={{color: 'rgb(50, 173, 161)'}}
                        >
                          <Button variant="contained" color="secondary">Edit This Issue</Button>
                        </Link>
                      </Card>
                    </Grid>
                  ) : null}
                  <Grid item>
                    <ContestShareCard />
                  </Grid>
                  <Grid item>
                    <ContestStateCard
                      currentStatus={contest.status}
                      isAdmin={this.props.isAdmin}
                      onSubmit={(event) => this.handleStatusUpdate(event)}
                    />
                  </Grid>
                  <Grid item>
                    <ContestSubmissionsCard
                      contest={contest}
                      legislationList={this.state.legislationList}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  var { contest, auth } = state;
  contest = contest.contest;

  return {
    contest,
    isAdmin: auth.isAdmin(),
    email: auth.fetchEmail(),
  };
}

export default connect(mapStateToProps)(ContestPage);

import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import propTypes from "prop-types";
import Logo from "../logo";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { styled, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { supabase } from "../../actions/shared/api";

import {
  DRAFTER_PAGE_URL,
  CONTEST_PAGE_URL,
  SUPPORT_PAGE_URL,
  DRAFT_GUIDE_PAGE_URL,
  ABOUT_PAGE_URL,
  LOGIN_PAGE_URL,
  POST_CONTEST_PAGE_URL,
} from "../../constants";
import { AccountCircle } from "@material-ui/icons";

const AppHeader = styled(AppBar)({
  backgroundColor: "#f8f9fa",
  color: "#444444",
});

const DesktopHeader = ({ isAuthenticated, logout }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [pageURL, setPageURL] = useState(null);

  // new auth
  const session = supabase.auth.session();

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLogOut = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    // logout the user.
    logout();
  };

  useEffect(() => {
    setPageURL(window.location.pathname);
  }, []);

  return (
    <div className="mb-4">
      <nav className="navbar fixed-top homepageNav">
        <div className="container-fluid">
          <a className="text-light text-decoration-none h3" href="#">
            Turq.io
          </a>

          <div className="d-none d-md-flex">
            {pageURL === "/" ? (
              <>
                <a
                  href="#about"
                  className="mx-4 text-decoration-none text-light"
                >
                  About
                </a>
                <a href="#how" className="mx-4 text-decoration-none text-light">
                  How it works
                </a>
                <a
                  href="#discover"
                  className="mx-4 text-decoration-none text-light"
                >
                  Discover
                </a>
              </>
            ) : null}
            <a href="/login" className="mx-4 text-decoration-none text-light">
              Login
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
};

const MobileHeader = ({ isAuthenticated, logout }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // new auth
  const session = supabase.auth.session();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const menuItems = [
    {
      menuTitle: "Explore Issues",
      pageURL: CONTEST_PAGE_URL,
      onClick: null,
    },
    {
      menuTitle: "Drafting Guidelines",
      pageURL: DRAFTER_PAGE_URL,
      onClick: null,
    },
    {
      menuTitle: "About",
      pageURL: ABOUT_PAGE_URL,
      onClick: null,
    },
    {
      menuTitle: session ? "Logout" : "Login",
      pageURL: LOGIN_PAGE_URL,
      onClick: logout,
    },
  ];

  return (
    <div style={{ flexGrow: 1 }}>
      <AppHeader position="static" className="background-primary">
        <Toolbar>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Link to="/">
                <Logo />
              </Link>
            </Grid>
            <Grid item>
              <IconButton
                edge="start"
                color="secondary"
                ariaLabel="menu"
                onClick={handleMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={() => setAnchorEl(null)}
              >
                {menuItems.map((menuItem) => {
                  const { menuTitle, pageURL, onClick } = menuItem;
                  return (
                    <Link to={pageURL} key={menuItem.menuTitle}>
                      <MenuItem
                        onClick={() => {
                          onClick && onClick();
                        }}
                      >
                        <Typography align="center" color="textPrimary">
                          {menuTitle}
                        </Typography>
                      </MenuItem>
                    </Link>
                  );
                })}
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppHeader>
    </div>
  );
};

const Header = ({ isAuthenticated, logout }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // new auth
  const session = supabase.auth.session();

  return (
    <>
      {isMobile ? (
        <MobileHeader isAuthenticated={session} logout={logout} />
      ) : (
        <DesktopHeader isAuthenticated={session} logout={logout} />
      )}
    </>
  );
};

export default Header;

Header.propTypes = {
  isAuthenticated: propTypes.bool,
};

Header.defaultProps = {
  isAuthenticated: false,
};

import React from "react";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper"
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {
  DEFAULT_CONTEST_CRITERIA,
  DEFAULT_CONTEST_RULES,
} from "../../constants";

function CompetitionText({ title, description, legislation, rules, endDate }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"), { noSsr: true });

  return (
    <Card>
      <CardContent>
        <h1
        >
          {title}
        </h1>
        
          <header>
          </header>
        <Accordion
          defaultExpanded={matches}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="text-primary" />}
            aria-controls="Issue-Description-content"
            id="Issue-Description-header"
          >
            <h2>
              Description
            </h2>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <ReactMarkdown source={description} />
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="text-primary"/>}
            aria-controls="Issue-Acceptance-content"
            id="Issue-Acceptance-header"
          >
            <h3>
              Legislation Needed
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <ReactMarkdown source={legislation} />
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="text-primary"/>}
            aria-controls="Issue-Rules-content"
            id="Issue-Rules-header"
            
          >
            <h3>
              Conditions
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <ReactMarkdown source={rules} className="text-primary"/>
              <ReactMarkdown source={DEFAULT_CONTEST_RULES} className="text-primary"/>
            </p>
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
}

export default CompetitionText;

CompetitionText.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  rules: PropTypes.string,
  endDate: PropTypes.string,
};

CompetitionText.defaultProps = {
  title: "",
  description: "",
  rules: "",
  endDate: "1970-1-1",
};

import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { useStore, useDispatch } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { POST_CONTEST_URL, TOKEN_ERROR_CODE } from "../../constants";
import Donation from "../payments/donation";
import DonationBreakdownCard from "../payments/donationBreakdownCard";
import {
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import Checkout from "../payments/checkout";
import { payment } from "../../actions/paymentsActions";
import { logout } from "../../actions/logout";
import { CardElement } from "@stripe/react-stripe-js";

export const FundingForm = (props) => {
  const [redirect, setRedirect] = useState("");
  const [totalFunding, setTotalFunding] = useState(0);
  const updateFunding = e => {
    console.log(e.target.id);
    setTotalFunding(parseInt(e.target.id));
  }

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      if (store.getState().payments.isSuccess) {
        localStorage.removeItem("postIssue");
        history.push(redirect);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [redirect]);

  const store = useStore();
  const goBack = () => {
    props.changeTab(3, 80);
  };
  const history = useHistory();
  return (
    <div hidden={props.value !== props.index} className="fundingForm">
      <h3 className="text-primary">The funding needed for your legislation is $200. Do you want to put something toward that today?</h3>
      <p className="text-primary">(It's ok if you don't, you can post the issue and share it on social media to crowd fund it below instead.)</p>
      <input type="text" className="hidden" id="fundingTotal" value={totalFunding}/>
      <Paper elevation={3}>
        <Donation saveIssue={props.saveIssue} setAmount={updateFunding} />
      </Paper>
      <Button color="secondary" variant="contained" className="mt-4" onClick={goBack}>Back</Button>
      <button className="btn btn-success ml-4 mt-4" onClick={props.submit}>
        Can't Fund? Post and share it
      </button>
    </div>
  );
};

export default FundingForm;

import React from "react";

const Hero = ({ buttonText }) => (
  <div className="hero">
    <div className="row h-100">
      <div className="col-12 heroTextBox">
        <h1 
          className="color-primary mt-4" 
          style={{
            fontSize: '4em',
            fontWeight: 'bold',
            textAlign: 'center',
            margin: '0.67em auto',
            width: '100%',
          }}
        >
          No more politicians. No more lawyers.
        </h1>
        <div className="text-container" style={{ color: '#000', fontSize: '1.3rem', paddingTop: '0.5em', textAlign: 'center', fontWeight: 'bold' }}>
          <p>Tired of endless political bickering? Change the status quo.</p>
          <p>Write real legislation effortlessly with our purpose-built editor—no politicians or expensive lawyers needed.</p>
          <p>Plan. Write. Share. Make an impact.</p>
        </div>
        <div style={{ textAlign: 'center', margin: '0 auto' }}>
          <a
            href={`${window.location.origin}/profile`}
            className="turq-primary-button" 
            style={{
              display: 'inline-block',
              margin: '80px auto 20px',
              padding: '20px 60px',
              fontSize: '2em',
              fontWeight: 'bold',
              textDecoration: 'none',
              borderRadius: '25px',
              backgroundColor: '#21D4FD',
              color: '#ffffff',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              border: 'none',
            }}
          >
            Start Writing
          </a>
          <p className="color-primary" style={{ marginTop: '-10px', marginLeft: '-30px' }}>Free to start</p>
        </div>
      </div>
    </div>
  </div>
);

export default Hero;

import React, { useState, useEffect, useCallback } from "react";
import Layout from "../../../components/layout/layout";
import {
  Grid,
  Modal,
} from "@material-ui/core";
import { supabase } from "../../../actions/shared/api";
import { toast } from "react-toastify";
import PasswordPane from "./passwordPane";
import OrganizationPane from "./organizationPane";
import useIsMounted from "react-is-mounted-hook";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const SettingsPage = () => {
  const email = localStorage.getItem("email");
  const isMounted = useIsMounted();
  const [pageShowing, setPageShowing] = useState("password");
  const [password, setPassword] = useState(null);
  const [existingOrgName, setExistingOrgName] = useState(null);
  const [existingOrgMembers, setExistingOrgMembers] = useState(null);
  const [orgName, setOrgName] = useState(null);
  const [newOrgModal, setNewOrgModal] = useState(false);
  const [organizationName, setOrganizationName] = useState(null);
  const [organizationMember, setOrganizationMember] = useState(null);
  const [allOrgMembers, setAllOrgMembers] = useState([]);
  const [addButtonDisabled, setAddButtonDisabled] = useState(false);

  const resetPassword = async () => {
    const session = JSON.parse(localStorage.getItem("supabase.auth.token"));
    const access_token = session.currentSession.access_token;

    if (access_token) {
      const { error, data } = await supabase.auth.api.updateUser(access_token, {
        password: password,
      });

      if (error) {
        console.log(error);
      } else {
        toast.success("Your password has been reset");
        console.log(data);
      }
    } else {
      toast.error(
        "looks like you didn't come here from a password reset email. Please check your email for a recovery link."
      );
    }
  };

  const loadExistingOrganization = async () => {
    const { data, error } = await supabase
      .from("users")
      .select("organization")
      .filter("email", "eq", `${email}`);

    if (error) {
      console.log(error);
    } else {
      if (data[0]?.organization) {
        setExistingOrgName(data[0].organization);
        loadExistingOrganizationMembers(data[0].organization);
      }
    }
  };

  const loadExistingOrganizationMembers = async (name) => {
    console.log(name);
    const { data, error } = await supabase
      .from("organizations")
      .select("members")
      .filter("name", "eq", `${name}`);

      if(error) {
        toast.error(error);
      } else { 
        setExistingOrgMembers(JSON.parse(data[0].members));
      }
    
  };

  const addOrganization = async () => {
    setPageShowing("loading");
    const theMember = localStorage.getItem("email");
    let theMembers = [];
    theMembers = [theMember];
    const { data, error } = await supabase
      .from("organizations")
      .insert([
        { started_by: theMember, members: theMembers, name: organizationName},
      ]);

    if (error) {
      console.log(error.message);
    } else {
      toast.success("Organization created");
      const { thedata, theerror } = await supabase
        .from("users")
        .update({ organization: organizationName })
        .match({ email: theMember });
        
    }
    setExistingOrgName(organizationName);
    setExistingOrgMembers(theMembers);
    setPageShowing("organization");
  };

  const deleteOrganization = async () => {
    setPageShowing("loading");
    const theMembers = [...existingOrgMembers];
    //console.log(existingOrgMembers);
  
    const { data, error } = await supabase
      .from('organizations')
      .delete()
      .match({ name: existingOrgName });
     
      if (error) {
        toast.error(error.message);
      } else {
       toast.success("organization deleted");
       const asyncRes = await Promise.all(theMembers.map(async (member) => {
        const { thedata, theerror } = await supabase
            .from("users")
            .update({ organization: null })
            .match({ email: member });
        }));

        setExistingOrgName(null);
        setExistingOrgMembers(null);
        setPageShowing("organization");
      }

      
  }

  const addMemberToExistingOrganizationMembers = async(member) => {
    let theMembers = [...existingOrgMembers];
    theMembers = [...theMembers, member];
    console.log(theMembers);
    setAddButtonDisabled(true);

    const { data, error } = await supabase
      .from("users")
      .update({ organization: existingOrgName })
      .match({ email: member });

      if(error) {
        toast.error(error);
      } else {
        const { thedata, theerror } = await supabase
          .from("organizations")
          .update({ members: theMembers })
          .match({ name: existingOrgName });

        setExistingOrgMembers(theMembers);
        document.getElementById("orgMember").value = "";
        setAddButtonDisabled(false); 
      }
  };

  const removeMemberFromOrganization = async(member) => {
    let theMembers = [...existingOrgMembers];
    theMembers = theMembers.filter((item) => item !== member);

    const { data, error } = await supabase
      .from("users")
      .update({ organization: null })
      .match({ email: member });

    if(error) {
      toast.error(error);
    } else {
      const { thedata, theerror } = await supabase
        .from("organizations")
        .update({ members: theMembers })
        .match({ name: existingOrgName });

      setExistingOrgMembers(theMembers);
    }
  };


  const handleSetNewOrgModal = () => {
    setNewOrgModal(!newOrgModal);
  };

  const handleSaveOrganization = (e) => {
    e.preventDefault();
    console.log(allOrgMembers);
    addOrganization();
  };

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem("supabase.auth.token"));
    loadExistingOrganization();
    if (!session) {
      window.location = "login";
    }
  }, []);

  return (
    <>
      <a className="m-4" href="/profile"><ArrowBackIcon /> Back</a>
      <Grid container spacing={2}>
        <Grid item xs={0} md={4} className="p-4">
          <h3 className="text-primary">Teams & Settings</h3>
          <p className="text-primary">
            <a
              href="#"
              id="password"
              onClick={(e) => setPageShowing(e.target.id)}
            >
              Reset Password
            </a>
          </p>
          <p className="text-primary">
            <a
              href="#"
              id="organization"
              onClick={(e) => setPageShowing(e.target.id)}
            >
              Teams
            </a>
          </p>
        </Grid>
        <Grid item xs={0} md={8} className="p-4">
          {pageShowing === "loading" ? (<p className="text-primary">Loading...</p>):null}
          {pageShowing === "password" ? (
            <PasswordPane
              resetPassword={resetPassword}
              setPassword={setPassword}
            />
          ) : null}
          {pageShowing === "organization" ? (
            <OrganizationPane
              handleSetNewOrgModal={handleSetNewOrgModal}
              setOrgName={setOrgName}
              addOrg={addOrganization}
              existingOrg={existingOrgName}
              existingOrgMembers={existingOrgMembers}
              addMemberToOrganization={addMemberToExistingOrganizationMembers}
              removeMemberFromOrganization={removeMemberFromOrganization}
              deleteOrganization={deleteOrganization}
              addButtonDisabled={addButtonDisabled}
            />
          ) : null}
        </Grid>
      </Grid>

      <Modal
        open={newOrgModal}
        onClose={handleSetNewOrgModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className="background-primary"
          style={{
            maxWidth: "500px",
            margin: "20px auto",
            borderRadius: "5px",
            padding: "20px",
          }}
        >
          <h3 className="text-primary">Create Team</h3>
          <label for="newIssueTitle" className="mt-4">
            Team Name
          </label>
          <form>
            <input
              type="text"
              className="form-control"
              id="orgName"
              name="orgName"
              placeholder="A great name"
              onChange={(e) => setOrganizationName(e.target.value)}
            />           
            <br />
            <hr />
            <button
              className="btn btn-success"
              onClick={(e) => handleSaveOrganization(e)}
            >
              Save Team
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default SettingsPage;

import React,{useState} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

const IssueDescriptionForm = (props) => {
    const [description,setDescription] = useState(null)
    const changeTab = () => {
        props.handleSetData('description', description);
        props.changeTab(2,60)
    }
    const goBack = () => {
        props.changeTab(0,20)
    }
    return (
        <div hidden={props.value !== props.index} className="issueDescriptionForm">
            <Typography variant="h4" className="text-primary">Write a description for your issue post</Typography>
            <TextField 
            multiline
            rows={25}
            variant="outlined"
            label="Required"
            value={description}
            data-title="description"
            className="issueInput"
            id="description"
            onChange={(event) => setDescription(event.target.value)}
            placeholder="Write description here "
            classes={{root:"headlineTextInput"}} InputProps={{classes:{
                root:"headlineTextInput",
                focused:"headlineTextInput",
                notchedOutline:"headlineTextInput"
            }}}
            />
            <Button onClick={changeTab} className="nextButton" disabled={description === ""}>Next</Button>
            <Button variant="contained"  color="secondary" onClick={goBack}>Back</Button>
        </div>
    )
}
export default IssueDescriptionForm
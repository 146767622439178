import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';

import NumberInput from "../editor/input/numberInput"

const Donation = ({setAmount, saveIssue}) => {

  const [active, setActive] = useState("");
  const [displayAmount, setDisplayAmount] = useState();

  const setButtonAmount = (value, active) => {
    //setAmount(value);
    setActive(active);
    setDisplayAmount("");
  }

  return (
    <Card className="checkout-card">
      <CardHeader title="Amount to Support" />
      <CardContent>
        <Grid container spacing={2} alignItems="center" justify="flex-start">
          {/*Remember we don't set decimals for stripe so those amounts are correct --- 500 = $5.00 */}
          <Grid item xs={6}>
            <button data-amount="5" size="large" className="btn btn-outline-secondary btn-block lato-font" onClick={() => saveIssue(5)} id="5"> $5 </button>
          </Grid>
          <Grid item xs={6}>
            <button size="large" className="btn btn-outline-secondary btn-block lato-font" onClick={() => saveIssue(10)} id="10"> $10 </button>
          </Grid>
          <Grid item xs={6}>
            <button size="large" className="btn btn-outline-secondary btn-block lato-font" onClick={() => saveIssue(20)} id="20"> $20 </button>
          </Grid>
          <Grid item xs={6}>
            <button size="large" className="btn btn-outline-secondary btn-block lato-font" onClick={() => saveIssue(50)} id="50"> $50 </button>
          </Grid>
          <Grid item xs={6}>
            <button size="large" className="btn btn-outline-secondary btn-block lato-font" onClick={() => saveIssue(100)} id="100"> $100 </button>
          </Grid>
          <Grid item xs={6}>
          <button size="large" className="btn btn-outline-secondary btn-block lato-font" onClick={() => saveIssue(200)} id="200"> $200 </button>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
      </CardActions>
    </Card>
  )
}

export default Donation

Donation.propTypes = {
  setAmount: PropTypes.func
}

Donation.defaultProps = {
  setAmount: null
}

import React from "react"
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';

import Layout from "../components/layout/layout"
import ContestPanelList from "../components/competition/contestPanelList"
import {isPastEndDate } from "../util/dateCompare"
import { supabase } from "../actions/shared/api";

class ContestPage extends React.Component {

  state = {
    issues: null,
  }
  
  componentDidMount() {
    this.fetchExistingIssues()
  }

  fetchExistingIssues = async() => {
    const { data, error } = await supabase
      .from("issues")
      .select("*");

    if (error) {
      return error;
    } else {
      this.setState({issues: data});
    }
  }

  render() {
    console.log(this.state.issues);
    var currentIssues = []
    var pastIssues = []
    if (this.state.issues) {
      currentIssues = this.state.issues
        .filter(issue => issue.active === 'true')
    }

    return (
      <div className="container pt-4">
        <a href="/">Home</a>
        {this.state.issues
          ? <>
            <Grid container className="background-primary">
              <Grid item xs={12} style={{textAlign: 'center'}}>
                <h3 className="text-primary m-0 py-4">Active Issues</h3>
                <ContestPanelList title="Active Issues" contests={currentIssues} />
              </Grid>
            </Grid>
          </>
          : <></>
        }
      </div>
    )
  }
}

function mapStateToProps(state) {

  const { contest } = state
  const { allContests, isFetching } = contest

  return {
    allContests,
    isFetching
  }

}


export default connect(mapStateToProps)(ContestPage)
import React, { useState, useEffect } from "react";
import { supabase } from "../../actions/shared/api";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import '../../scss/chat.scss';

const Chat = ({ organizationId }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  const fetchMessages = async () => {
    const { data, error } = await supabase
      .from("messages")
      .select("*")
      .eq("organization_id", organizationId)
      .order("created_at", { ascending: true });

    if (error) {
      toast.error(error.message);
    } else {
      setMessages(data);
      console.log("Fetched messages:", data);
    }
  };

  const [retryCount, setRetryCount] = useState(0);

  let subscription;
  const subscribeToMessages = () => {
    subscription = supabase
    .from(`messages:organization_id=eq.${organizationId}`)
    .on("INSERT", (payload) => {
      setMessages((prevMessages) => [...prevMessages, payload.new]);
    })
    .on("ERROR", error => {
      console.error("Supabase subscription error:", error);
    })
    .subscribe();  
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    const userEmail = localStorage.getItem("email");
    const { data, error } = await supabase.from("messages").insert([
      {
        organization_id: organizationId,
        user_email: userEmail,
        content: newMessage,
        created_at: new Date(),
      },
    ]);

    if (error) {
      toast.error(error.message);
    } else {
      setNewMessage("");
    }
  };

  useEffect(() => {
    fetchMessages();
    subscribeToMessages();

    /* return () => {
      supabase.from(`messages:organization_id=eq.${organizationId}`).unsubscribe();
    };    */
  }, [organizationId]);

  return (
    <div className="chat-container">
      <form onSubmit={sendMessage}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message"
        />
        <button type="submit"><FontAwesomeIcon icon={faPaperPlane} /></button>
      </form>
      <div className="messages">
        {messages.map((message) => (
          <div key={message.id} className="message">
            <strong>{message.user_email}:</strong> {message.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Chat;

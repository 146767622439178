import React from "react"
import { Route, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { toast } from 'react-toastify'

function PrivateRoute({ component: Component, ...rest }) {

  const isAuthenticated = () => {
    const session = localStorage.getItem('supabase.auth.token');
    return session;
  }

  if (!isAuthenticated) {
    toast.info("Please log in to view this page, you will be automatically redirected after login")
  }

  return (
    <Route {...rest} render={props =>
        isAuthenticated
        ? <Component {...props} />
        : <Redirect to={{ pathname: "/login", state: { referer: props.location } }} />
      }
    />
  );
}


export default PrivateRoute;
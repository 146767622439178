import React from "react";
import { Card } from "@material-ui/core";

const PasswordPane = props => {
  return (
    <Card variant="outlined" className="background-secondary p-4">
      <h1 className="text-gold">Enter your new password</h1>
      <label className="mt-4 text-white" for="email">
        New Password
      </label>
      <input
        required
        type="text"
        onChange={(e) => props.setPassword(e.target.value)}
        className="form-control"
        id="password"
        placeholder="Enter new password"
      />
      <button onClick={props.resetPassword} className="turq-secondary-button mt-4">
        Update Password
      </button>
    </Card>
  );
};

export default PasswordPane;

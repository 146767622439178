import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import { Blog } from './blog';

export const BlogPost = () => {
    const [post, setPost] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    let { title } = useParams();

    https://api.dropinblog.com/v1/json/post/?b=XXXXXXXXXXXXXXXXXXXXXX&post=discounts-this-spring

    useEffect(() => {
        console.log(title)
        fetch(`https://api.dropinblog.com/v1/json/post/?b=591bd587-9682-4a53-8063-c835f185c0fe&post=${title}`)
            .then((response) => response.json())
            .then((data) => {
                setPost(data.data.post);
                setIsLoading(false);
                console.log(data.data.post);
            }); 
    }, []);

    const content = useMemo(() => {
        if(isLoading) {
            return (
                <div className="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <h1>{post.title}</h1> 
                    <img className="w-100" src={post.featuredImage}/>
                    <div className="container p-4 mw-100">
                        {ReactHtmlParser(post.content)}
                    </div>
                </div>
            )
        }
    }, [isLoading]);

    return (
        <>
        <nav className="navbar fixed-top homepageNav">
          <div className="container-fluid">
            <a className="text-light text-decoration-none h3" href="/">
              writelegislation.com
            </a>

            <div className="d-none d-md-flex">
              <a href="#about" className="mx-4 text-decoration-none text-light">
                About
              </a>
              <a href="#how" className="mx-4 text-decoration-none text-light">
                How it works
              </a>
              <a href="/blog" className="mx-4 text-decoration-none text-light">
                Blog
              </a>
              <a href="#discover" className="mx-4 text-decoration-none text-light">
                Discover
              </a>
              <a href="/login" className="mx-4 text-decoration-none text-light">
                Login
              </a>
            </div>
          </div>
        </nav>
        
        <div className="container blog-container p-4">
            <div className="col-12 col-md-8 offset-md-2">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item"><a href="/blog">Blog</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{post?.title}</li>
                    </ol>
                </nav>
                {content}
            </div>
        </div>
        </>
    )
};

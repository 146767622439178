import React, { useState, useEffect } from "react";
import { supabase } from "../../actions/shared/api";
import { toast } from "react-toastify";
import SortableIssuesList from "../users/SortableIssuesList";
import { Fab, Modal } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import move from "lodash-move";
import Chat from "../organization/Chat";

const Organization = () => {
  const [pageShowing, setPageShowing] = useState("posted");
  const [organizationName, setOrganizationName] = useState("hey");
  const [organizationMembers, setOrganizationMembers] = useState([]);
  const [allIssues, setAllIssues] = useState([]);
  const [postedIssues, setPostedIssues] = useState([]);
  const [editing, setEditing] = useState(false);
  const email = localStorage.getItem("email");
  const [organizationId, setOrganizationId] = useState(null);
  const [filteredIssues, setFilteredIssues] = useState([]);
  const [currentFilter, setCurrentFilter] = useState("");
  const [isFiltered, setIsFiltered] = useState(false);

  //Modal stuff
  const [newIssueModal, setNewIssueModal] = useState(false);
  const [newIssueTitle, setNewIssueTitle] = useState(null);
  const [newIssueDescription, setNewIssueDescription] = useState(null);
  const [newIssueLegislation, setNewIssueLegislation] = useState(null);
  const [newIssueCity, setNewIssueCity] = useState(null);
  const [newIssueState, setNewIssueState] = useState(null);

  const loadOrganization = async () => {
    let name = window.location.search.split("=");
    name = decodeURI(name[1]);
    setOrganizationName(name);
  
    const { data, error } = await supabase
      .from("organizations")
      .select("*")
      .filter("name", "eq", `${name}`);
  
    if (error) {
      toast.error(error);
    } else {
      setOrganizationId(data[0].id);
      console.log(data);
      loadIssuesForOrganization(data[0].id);
    }
};

  const loadIssuesForOrganization = async (orgId) => {
    const { data, error } = await supabase
      .from("issues")
      .select("*")
      .eq("organization_id", orgId);

    if (error) {
      toast.error(error);
    } else {
      setAllIssues(data);
    }
  };

  const deleteIssue = async (issueID) => {};

  const handleCreateIssue = async (e) => {
    e.preventDefault();
    const { data, error } = await supabase.from("issues").insert([
      {
        title: newIssueTitle,
        description: newIssueDescription,
        legislation: newIssueLegislation,
        user: email,
        endDate: "",
        totalFunding: 200,
        currentFunding: 0,
        status: "new",
        active: "false",
        organization_id: organizationId  // <<<<<< Add this
      },
    ]);
  
    if (error) {
        toast(error);
      } else {
        loadIssuesForOrganization();
        handleNewIssueModal();
      }
  };  

  const displayIssues = isFiltered ? filteredIssues : allIssues;

  const addNewIssueToOrg = async (id) => {
    let issues = [
      ...allIssues,
      {
        id: id,
        title: newIssueTitle,
        description: newIssueDescription,
        legislation: newIssueLegislation,
        user: email,
        totalFunding: 200,
        currentFunding: 0,
        status: "new",
        active: "false",
      },
    ];
    setAllIssues(issues);

    const { data, error } = await supabase
      .from("organizations")
      .update({ all_issues: issues })
      .match({ name: organizationName });

    if (error) {
      toast(error);
    } else {
      toast("Issue saved");
    }
  };

  const updateIsueOrder = async ({ oldIndex, newIndex }) => {
    let newOrder = move(allIssues, oldIndex, newIndex);
    setAllIssues(newOrder);

    const { data, error } = await supabase
      .from("organizations")
      .update({ all_issues: newOrder })
      .match({ name: organizationName });

    if (error) {
      toast(error);
    } else {
      toast("issue order saved");
    }
  };

  const handleNewIssueModal = () => {
    setNewIssueModal(!newIssueModal);
  };

  const handlePageChange = (e) => {
    const theID = e.target.id;
    setPageShowing(theID);
  };

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem("supabase.auth.token"));
    loadOrganization();
    if (!session) {
      window.location = "login";
    }
  }, []);

  const filterIssues = (value) => {
    if (value === "clearAllFilters") {
        removeAllFilters();
        return;
    }
    setIsFiltered(false);
    if (value === "ALL") {
        setFilteredIssues(allIssues);
    } else {   
        const result = allIssues.filter(issue => issue.status === value);    
        if(result.length > 0) {
            setIsFiltered(true);
        }
        setFilteredIssues(result);
    }
    setCurrentFilter(value);
};

  const removeAllFilters = () => {
    setFilteredIssues([]);
    setIsFiltered(false);
  };

  return (
    //<div className="parent-container">
    <div className="organization-container">
    <div className="organization-content">
      <div style={{ display: "block" }} className="p-4">
   <div style={{ display: "block"}} className="p-4">
      <a href="/profile">Back to profile</a>
      <h1 className="text-primary text-center">{organizationName}</h1>
      <div style={{ marginLeft: "70%" }}>
        <select onChange={(e) => filterIssues(e.target.value)}>
        <option value="">Filter</option>
        <option value="draft">draft</option>
        <option value="new">new</option>
        <option value="research">research</option>
        <option value="funding">funding</option>
        <option value="writing">writing</option>
        <option value="submitting">submitting</option>
        <option value="archived">archive</option>
        <option value="clearAllFilters">Clear Filters</option>
        </select>
      </div>
      <Fab
        className="mt-4 ml-4"
        color="secondary"
        aria-label="add"
        onClick={handleNewIssueModal}
      >
        <Add />
      </Fab>
      <SortableIssuesList
        issues={displayIssues}
        editing={false}
        deleteIssue={deleteIssue}
      />
      <Modal
        open={newIssueModal}
        onClose={handleNewIssueModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className="background-primary"
          style={{
            maxWidth: "500px",
            margin: "20px auto",
            borderRadius: "5px",
            padding: "20px",
          }}
        >
          <h3 className="text-primary" style={{ textAlign: "center" }}>
            Add a new issue
          </h3>
          <form>
            <div className="form-group">
              <label for="newIssueTitle">Title</label>
              <input
                type="text"
                className="form-control"
                id="newIssueTitle"
                name="newIssueTitle"
                placeholder="My amazing title"
                onChange={(e) => setNewIssueTitle(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="newIssueDescription">Description</label>
              <textarea
                className="form-control"
                id="newIssueDescription"
                name="newIssueDescription"
                rows="3"
                onChange={(e) => setNewIssueDescription(e.target.value)}
                placeholder="Describe the issue you are raising in a little more detail"
              ></textarea>
            </div>
            <div className="form-group">
              <label for="newIssueLegislation">Legislation</label>
              <textarea
                className="form-control"
                id="newIssueLegislation"
                name="newIssueLegislation"
                rows="3"
                onChange={(e) => setNewIssueLegislation(e.target.value)}
                placeholder="Describe the legislation you would like to see enacted around this issue"
              ></textarea>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label for="newIssueCity">City</label>
                <input
                  type="text"
                  className="form-control"
                  id="newIssueCity"
                  name="newIssueCity"
                  onChange={(e) => setNewIssueCity(e.target.value)}
                  placeholder=""
                />
              </div>
              <div className="col">
                <label for="newIssueState">State</label>
                <input
                  type="text"
                  className="form-control"
                  id="newIssueState"
                  name="newIssueState"
                  onChange={(e) => setNewIssueState(e.target.value)}
                  placeholder=""
                />
              </div>
            </div>
            <button className="btn btn-success" onClick={e => handleCreateIssue(e)}>
              Create
            </button>
            <button
              className="btn btn-danger ml-3"
              onClick={handleNewIssueModal}
            >
              Cancel
            </button>
          </form>
        </div>
      </Modal>
    </div>
    </div>
    </div>
    {organizationId && (
      <div className="chat-section">
        <Chat organizationId={organizationId} />
      </div>
    )}
  </div>
  //</div>
  );
};

export default Organization;

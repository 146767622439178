import React from "react";
import { withRouter } from "react-router-dom";
import "../scss/switch.scss";

import Layout from "../components/layout/layout";
import Hero from "../components/hero";
import AppSteps from "../components/AppSteps";
import PetitionSection from "../components/PetitionSection";
import SingleSourceSection from "../components/SingleSourceSection";
import LegislatorSection from "../components/LegislatiorSection";
import TestimonialSection from "../components/TestimonialSection";
import IssueCardsSection from "../components/IssueCardsSection";
import { isPastEndDate } from "../util/dateCompare";
import { CONTEST_PAGE_URL, POST_CONTEST_PAGE_URL } from "../constants";
import { FaChevronRight } from "react-icons/fa";
import { Button } from "@material-ui/core";
import { supabase } from "../actions/shared/api";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      switch1: true,
      contests: null,
    };
  }

  handleSwitchChange = (nr) => () => {
    let switchNumber = `switch1`;
    this.setState({
      [switchNumber]: !this.state[switchNumber],
    });
  };

  redirectIfPasswordReset = () => {
    const query = new URLSearchParams(window.location.search);
    const type = query.get("type");
    console.log(type);
    if (type) {
      this.props.history.push({
        pathname: `/password_reset`,
      });
    }
  };

  componentDidMount = async () => {
    const { data, error } = await supabase
      .from("issues")
      .select("*")
      .match({ status: "posted", active: "true" });

    if (error) {
      return error;
    } else {
      this.setState({ contests: data });
    }
  };

  render() {
    this.redirectIfPasswordReset();
    var currentContests = null;
    if (this.state && this.state.contests) {
      currentContests = this.state.contests.filter(
        (contest) => !isPastEndDate(contest.endDate)
      );
    }
    if (!this.state.switch1) {
      currentContests = this.state.contests.filter((contest) =>
        isPastEndDate(contest.endDate)
      );
    }
    return (
      <>
        <nav className="navbar fixed-top homepageNav">
          <div className="container-fluid">
            <a className="primary-link h3" href="/">
              <img src="/images/QuorumLogo.png" className="w-50 rounded"/>
            </a>

            <div className="d-none d-md-flex">
              <a href="#about" className="mx-4 primary-link">
                About
              </a>
              <a href="https://writelegislation.com/blog/post/how-to-use-writelegislation" className="mx-4 primary-link">
                How it works
              </a>
              <a href="/blog" className="mx-4 primary-link">
                Blog
              </a>
              <a href="/login" className="mx-4 primary-link">
                Login
              </a>
            </div>
          </div>
        </nav>
        <Hero buttonText="Learn More" />
        <div className="clearfix"></div>
        <div className="" id="about">
          <div className="container">
            <div className="row py-5">
              <div className="col-12 col-md-6 text-center text-md-start p-4 mt-5">
                <h2 className="pt-5 pl-5 fw-bold color-primary">
                  We make it easy to see real change
                </h2>
                <h4 className="color-secondary">Simple writing tools</h4>
                <p className="pt-2 w-70">
                Writelegislation.com empowers you to make your voice heard by writing legislation, sharing with your friends and community, and sending to your lawmakers. Our simple, user-friendly writing tool allows you to easily write legislation for topics you care about, using your own words.
                </p>
                <a href="/login" className="primary-link fs-4 mt-2">
                  Sign Up for Free
                </a>
              </div>
              <div className="col-12 col-md-6 text-center text-md-start">
                <img
                  className="rounded-5 w-100 p-4 mt-5"
                  src="/images/addIssue.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
        <div className="wins background-secondary pb-5">
          <div className="container">
            <hr className="homeHr"/>
            <h3 className="text-center pt-5 pb-3 color-secondary">Our Wins</h3>
            <p className="text-center color-secondary">Here are a few issues we currently have in progress on our platform</p>
            <div className="row">
              <div className="col-12 col-md-3">
                <div className="card mt-5 winCard">
                  <img className="winCardImage" src="/images/Ellipse5.png"/>
                  <p className="p-3 text-center color-primary">Require police departments to leverage voice analysis software to provide data and training on de-escalation language. </p>
                  <p className="p-3 text-center color-secondary">Introduced in the NC Legislature</p>
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div className="card mt-5 winCard">
                  <img className="winCardImage" src="/images/Ellipse2.png"/>
                  <p className="p-3 text-center color-primary">In-state University tution for all veterans, regardless of residence.</p>
                  <p className="p-3 text-center color-secondary">Introduced in the MA Legislature</p>
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div className="card mt-5 winCard">
                  <img className="winCardImage" src="/images/Ellipse3.png"/>
                  <p className="p-3 text-center color-primary">Require police departments to leverage voice analysis software to provide data and training on de-escalation language.</p>
                  <p className="p-3 text-center color-secondary">Submitted to the MI Legislature</p>
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div className="card mt-5 winCard">
                  <img className="winCardImage" src="/images/Ellipse2.png"/>
                  <p className="p-3 text-center color-primary">Limit the use of pesticides on golf courses.</p>
                  <p className="p-3 text-center color-secondary">Introduced in the MA Legislature</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="steps">
          <div className="container">
            <div className="row pt-5">
              <div className="col-12 col-md-6 d-none d-md-flex">
                <img
                  className="rounded-5 h-100 w-100 p-4"
                  src="/images/appScreen1.png"
                />
              </div>
              <div className="col-12 col-md-6">
                <h3 className="pt-5 fw-bold color-primary">Collaboration Hub</h3>
                <h4 className="color-secondary">Work with your team or community</h4>
                <p className="pt-2">
                Our built-in crowdfunding platform helps you raise money to compensate any writing assistants, build awareness for your cause, and share your list of legislation with your team or community. Collaboration has never been so easy. 
                </p>
                <a href="/login" className="primary-link fs-4 mt-2">
                  Raise an Issue
                </a>
              </div>
              <div className="col-12 col-md-6 d-flex d-md-none">
                <img
                  className="rounded-5 h-100 w-100 p-4"
                  src="/images/appScreen1.png"
                />
              </div>
            </div>
            <hr className="homeHr"/>
            <div className="row pt-5">
              <div className="col-12 col-md-6">
                <h3 className="pt-5 fw-bold color-primary">User-friendly Templates</h3>
                <h4 className="color-secondary">On-demand legislative writers</h4>
                <p className="pt-2">
                Never feel intimidated. Writelegislation.com makes writing legislation yourself easy with templates and guides, but we can also connect you with a legislative writing assistant who can do the work for you to make your dreams a reality.
                </p>
                <a href="https://forms.gle/bztfHnsHYiyhZbqZ6" className="primary-link fs-4 mt-2">
                  Writing Assistant Sign Up
                </a>
              </div>
              <div className="col-12 col-md-6">
                <img
                  className="rounded-5 h-100 w-100 p-4"
                  src="/images/appScreen2.png"
                />
              </div>
            </div>
            <hr className="homeHr"/>
            <div className="row pt-5">
              <div className="col-12 col-md-6 d-none d-md-flex">
                <img
                  className="rounded-5 h-100 w-100 p-4"
                  src="/images/appScreen4.png"
                />
              </div>
              <div className="col-12 col-md-6">
                <h3 className="pt-5 fw-bold color-primary">Powerful Editor</h3>
                <h4 className="color-secondary">Pre-loaded templates</h4>
                <p className="pt-2">
                Using local and state legislation templates, our editor makes it easy to alter the language you want, removing the guess-work. And multiple users can make and see edits, ensuring full transparency for your team and community. When finished, a signature-ready PDF is ready to be sent to your lawmakers.
                </p>
                <a href="https://forms.gle/mx3anGkTLdPa7mpZ9" className="primary-link fs-4 mt-2">
                  Make Money as an Affiliate
                </a>
              </div>
              <div className="col-12 col-md-6 d-flex d-md-none">
                <img
                  className="rounded-5 h-100 w-100 p-4"
                  src="/images/appScreen4.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix"></div>

        <div className="clearfix"></div>
        <footer className="">
          <div className="row py-4 background-secondary">
            <div className="col-12 col-md-4">
              <p className="text-center text-gold">Copyright Turq, pbc</p>
            </div>
            <div className="col-12 col-md-4">
            <p className="text-center text-gold">Contact (336) 398-4048 or tim@writelegislation.com</p>
            </div>
            <div className="col-12 col-md-4 text-center">
              <a className="primary-link text-center" href="http://writelegislation.com/terms/Terms_of_Service.pdf">Terms of service</a>
              <p> </p>
              <a className="footer-link" href="https://writelegislation.com/terms/privacy_policy">Privacy Policy</a>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default withRouter(Home);

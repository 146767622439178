import React, { useState, useEffect } from 'react';
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import { Card, CardContent, Fab, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import { supabase } from "../../actions/shared/api";

const DragHandle = sortableHandle(() => <DragHandleIcon />);

const SortableItem = SortableElement(({ value, onDelete }) => (
  <Card style={{ marginBottom: '7px', marginLeft: '50px', width: '50%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '20px' }}>
    <CardContent style={{ display: 'flex', alignItems: 'center' }}>
      <DragHandle />
      <Link to={`/editor/legislation?contest=${value.contest_id}&legislationID=${value.id}`} style={{ marginLeft: '10px', textDecoration: 'none', flexGrow: 1 }}>
        <div style={{ fontSize: '1.5rem', color: value.title ? 'inherit' : '#aaa' }}>
          {value.title || "Untitled Legislation"}
        </div>
      </Link>
    </CardContent>
    <IconButton onClick={() => onDelete(value.id)} style={{ marginRight: '10px', color: 'red' }}> 
      <DeleteIcon />
    </IconButton>
  </Card>
));

const SortableList = SortableContainer(({ items, onDelete }) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem key={`item-${value.id}`} index={index} value={value} onDelete={onDelete} />
      ))}
    </div>
  );
});

const LegislationListPage = () => {
  const [legislation, setLegislation] = useState([]);

  useEffect(() => {
    const fetchLegislation = async () => {
      const user = supabase.auth.user();
      if (!user) {
        console.error('User must be logged in to fetch legislation');
        return;
      }

      let { data, error } = await supabase
        .from('legislation')
        .select('*')
        .eq('user', user.email)
        .order('order', { ascending: true });

      if (error) {
        console.error('Error fetching legislation:', error);
        return;
      }

      setLegislation(data);
    };

    fetchLegislation();
  }, []);

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    const reorderedLegislation = arrayMoveImmutable(legislation, oldIndex, newIndex);
    setLegislation(reorderedLegislation);

    // Sequentially update the order in the database
    for (let i = 0; i < reorderedLegislation.length; i++) {
      const item = reorderedLegislation[i];
      await supabase
        .from('legislation')
        .update({ order: i })
        .eq('id', item.id);
    }
  };

  const deleteLegislation = async (id) => {
    try {
      const { error } = await supabase
        .from('legislation')
        .delete()
        .eq('id', id);

      if (error) {
        throw error;
      }

      // Remove the item from state
      setLegislation(legislation.filter((item) => item.id !== id));
    } catch (error) {
      console.error('Error deleting legislation:', error);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ marginBottom: '20px' }}>
        <Link to="/profile" style={{ textDecoration: 'none', color: '#333', display: 'block', marginBottom: '10px' }}>
          Back to profile
        </Link>
        <Link to={`/editor/legislation`} style={{ textDecoration: 'none' }}>
          <Fab color="primary" size="small" aria-label="add">
            <AddIcon />
          </Fab>
        </Link>
      </div>
      <SortableList items={legislation} onSortEnd={onSortEnd} useDragHandle onDelete={deleteLegislation} />
    </div>
  );
};

export default LegislationListPage;

import React, { useState, useEffect } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Grid from "@material-ui/core/Grid";
import {
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import { supabase } from "../actions/shared/api";
import { THANKYOU_URL } from "../constants";
import Layout from "../components/layout/layout";
import Checkout from "../components/payments/checkout";
import Donation from "../components/payments/donation";
import { payment } from "../actions/paymentsActions";
import { stripePaymentLinks } from "../constants";
import DonationBreakdownCard from "../components/payments/donationBreakdownCard";
import AddressCard from "../components/payments/addressCard";

function CheckoutForm({
  location,
  dispatch,
  isComplete,
  isSuccess,
  isFetching,
}) {
  const history = useHistory();
  const id = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const [cardName, setCardName] = useState("");
  const [amount, setAmount] = useState(0);
  const contestId = window.location.pathname.split("/")[2];

  const goToPaymentPage = (paymentAmount) => {
    
      // Set localstorage so that on the contest page we can pull these and populate the page
      localStorage.setItem("postedIssuePayment", paymentAmount);
      localStorage.setItem("postedIssue", contestId);
      window.location = stripePaymentLinks["prod"][paymentAmount].link;
    
  };

  const handleChange = (event) => {
    setCardName(event.target.value);
  };

  return (
    <>
      {!isFetching && isComplete && isSuccess ? (
        <Redirect to={THANKYOU_URL} />
      ) : (
        <Layout fullWidth pageTitle="Checkout">
          <div className="checkout-page">
            <Grid container spacing={5} justify="center" alignItems="stretch">
              <Grid container item xs={12} md={6}>
                <Donation
                  setAmount={setAmount}
                  saveIssue={goToPaymentPage}
                />
              </Grid>
            </Grid>
          </div>
        </Layout>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { auth, payments } = state;
  const { isAuthenticated } = auth;
  const { isFetching, isComplete, isSuccess } = payments;

  return {
    isFetching,
    isComplete,
    isSuccess,
    isAuthenticated,
  };
}

export default connect(mapStateToProps)(CheckoutForm);

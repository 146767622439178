/* global gapi */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Grid, Card } from "@material-ui/core";
import Tabs, { TabPane } from "rc-tabs";
import Layout from "../components/layout/layout";
import { supabase } from "../actions/shared/api";
import { toast } from "react-toastify";

const LoginPage = () => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState();
  const [tabValue, setTabValue] = useState(0);
  let history = useHistory();
  let dispatch = useDispatch();

  const logInUser = async () => {
    const { user, session, error } = await supabase.auth.signIn({
      email: email,
      password: password,
    });

    if (error) {
      toast.error(error.message);
    } else {
      localStorage.setItem("email", email);
      history.push("/profile");
    }
  };

  const insertUser = async () => {
    const { data, error } = await supabase.from("users").insert([
      {
        email: email,
      },
    ]);

    if (error) {
      toast.error(error.message);
    } else {
      toast.success("Welcome! We are glad to have you with us");
      localStorage.setItem("email", email);
      history.push("/profile");
    }
  };

  const addUser = async () => {
    const { user, session, error } = await supabase.auth.signUp({
      email: email,
      password: password,
    });

    if (error) {
      toast.error(error.message);
    } else {
      insertUser();
    }
  };

  const sendPasswordResetEmail = async() => {
    if(!email) {
      toast.error("please enter your email so we know who you are.");
    } else {
      const { data, error } = await supabase.auth.api
        .resetPasswordForEmail(email);

      if(error) {
        toast.error(error.message);
      } else {
        toast.success("password reset email sent");
      }
    } 
  }

  const handleAddUser = e => {
    e.preventDefault();
    addUser();
  }

  const handleSubmit = e => {
    e.preventDefault();
    logInUser();
  };

  const handleForgottenPassword = e => {
    e.preventDefault();
    sendPasswordResetEmail();
  }

  return (
    <div className="pt-4">
      <Grid container spacing={2}>
        <Grid item xs={0} md={4} className="p-4"></Grid>
        <Grid item xs={0} md={4} className="p-4">
          <Card variant="outlined" className="background-secondary p-4">
          <img src="/images/QuorumLogo.png" className="w-50"/>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Sign In" key="1">
                <form>
                  <div className="form-group">
                    <label className="color-secondary" for="email">Email address</label>
                    <input
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      className="form-control"
                      id="email"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                    />
                    <small id="emailHelp" className="form-text text-muted">
                      We'll never share your email with anyone else.
                    </small>
                  </div>
                  <div class="form-group">
                    <label className="color-secondary" for="password">Password</label>
                    <input
                      required
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      className="form-control"
                      id="password"
                      placeholder="Password"
                    />
                  </div>
                  <button
                    onClick={handleSubmit}
                    type="submit"
                    id="login"
                    className="turq-primary-button mt-4"
                  >
                    Login
                  </button>
                  <p className="mt-4"><a onClick={handleForgottenPassword} className="primary-link" href="#">Forgotten password</a></p>
                </form>
              </TabPane>
              <TabPane tab="Sign Up" key="2">
                <form>
                  <div className="form-group">
                    <label for="email" className="color-secondary">Email address</label>
                    <input
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      className="form-control"
                      id="email"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                    />
                    <small id="emailHelp" className="form-text text-muted">
                      We'll never share your email with anyone else.
                    </small>
                  </div>
                  <div className="form-group">
                    <label for="password" className="color-secondary">Password</label>
                    <input
                      required
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      className="form-control"
                      id="password"
                      placeholder="Password"
                    />
                  </div>
                  <div className="row">
                    <p className="pl-3 form-text text-muted">By signing up you agree to our <a className="text-white" href="/terms/Terms_of_Service.pdf">Terms or Service</a></p>
                    <div className="form-check ml-3">
                      <input type="checkbox" className="form-check-input" id="termsCheck" required/>
                      <label className="form-check-label color-white ml-4" for="termsCheck">I agree</label>
                    </div>
                  </div>
                  <button
                    onClick={handleAddUser}
                    type="submit"
                    id="signUp"
                    className="turq-primary-button mt-3"
                  >
                    Sign Up
                  </button>
                </form>
              </TabPane>
            </Tabs>
          </Card>
        </Grid>
        <Grid item xs={0} md={4}></Grid>
      </Grid>
    </div>
  );
};

export default LoginPage;

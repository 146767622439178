import React, { useEffect, useMemo, useState } from 'react';

export const Blog = () => {
    const [blogPosts, setBlogPosts] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch('https://api.dropinblog.com/v1/json/?b=591bd587-9682-4a53-8063-c835f185c0fe')
            .then((response) => response.json())
            .then((data) => {
                setBlogPosts(data.data.posts);
                console.log(data.data.posts);
                setIsLoading(false);
            });
    }, []);

    const content = useMemo(() => {
        if(isLoading) {
            return (
                <div className="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="row">
                {blogPosts?.map(post => {
                    return (
                        <div className="col-12 col-md-6 p-2">
                            <div className="card blog-post">
                                <img className="w-100" src={post.featuredImage}/>
                                <div class="card-body">
                                    <h3 className="py-3">{[post.title]}</h3>
                                    <p>{post.summary}</p>
                                    <a href={`/blog/post/${post.slug}`} className="primary-link">View Post</a>
                                </div>
                            </div>
                        </div>
                    )
                })}
                </div>
            )
        }
        
    }, [isLoading]);

    return (
        <>
        <nav className="navbar fixed-top homepageNav">
          <div className="container-fluid">
            <a className="text-light text-decoration-none h3" href="/">
              writelegislation.com Home
            </a>

            <div className="d-none d-md-flex">
              <a href="#about" className="mx-4 text-decoration-none text-light">
                About
              </a>
              <a href="#how" className="mx-4 text-decoration-none text-light">
                How it works
              </a>
              <a href="/blog" className="mx-4 text-decoration-none text-light">
                Blog
              </a>
              <a href="/login" className="mx-4 text-decoration-none text-light">
                Login
              </a>
            </div>
          </div>
        </nav>
        <div className="container blog-container">
            <h1 className="text-center py-4">The writelegislation.com Blog</h1>
            {content}
        </div>
        </>
    )
}

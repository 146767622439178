import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Layout from "../components/layout/layout";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { toast } from "react-toastify";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { supabase } from "../actions/shared/api";
import HeadlineForm from "../components/forms/headline.form";
import IssueDescriptionForm from "../components/forms/issue-description.form";
import LegislationForm from "../components/forms/legislation.form";
import LocationForm from "../components/forms/location.form";
import FundingForm from "../components/forms/funding.form";
import HeadlineDisclaimer from "../components/disclaimers/headline.disclaimer";
import IssueDescriptionDisclaimer from "../components/disclaimers/issue-description.disclaimer";
import LegislationDisclaimer from "../components/disclaimers/legislation.disclaimer";
import LocationDisclaimer from "../components/disclaimers/location.disclaimer";
import FundingDisclaimer from "../components/disclaimers/funding.disclaimer";
import { useStripe, useElements, Elements } from "@stripe/react-stripe-js";
import { stripePaymentLinks } from '../constants';

const PostContestPage = () => {
  const stripe = useStripe(); // change to useStripe
  const dispatch = useDispatch();
  const email = localStorage.getItem("email");
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [progress, setProgress] = useState(20);
  const [issueData, setIssueData] = useState({
    title: null,
    description: null,
    user: email,
    endDate: "12-13-2022",
    totalFunding: "200.00",
    currentFunding: "0.00",
  });

  const saveIssueWithPayment = async(paymentAmount) => {
    const { data, error } = await supabase.from("issues").insert([
      {
        title: document.getElementById("title").value,
        description: document.getElementById("description").value,
        user: email,
        endDate: "12-13-2022",
        totalFunding: 200,
        currentFunding: 0,
      },
    ]);
    // If there is an error saving the issue, show that error to the user
    if (error) {
      toast(error);
    } 
    // If there was a payment amount then we need to take the user to the stripe payment page otherwise redirect them to the issue page
    if (paymentAmount != undefined) {
    // Set localstorage so that on the contest page we can pull these and populate the page
      localStorage.setItem("postedIssuePayment", paymentAmount);
      localStorage.setItem("postedIssue", data[0].id);
      window.location = stripePaymentLinks['prod'][paymentAmount].link;
    } else {
        history.push(`/contest/${data[0].id}`);
    }
  };

  const saveIssue = async(paymentAmount) => {
    const { data, error } = await supabase.from("issues").insert([
      {
        title: document.getElementById("title").value,
        description: document.getElementById("description").value,
        user: email,
        endDate: "12-13-2022",
        totalFunding: 200,
        currentFunding: 0,
      },
    ]);
    // If there is an error saving the issue, show that error to the user
    if (error) {
      toast(error);
    } 
    // If there was a payment amount then we need to take the user to the stripe payment page otherwise redirect them to the issue page
    history.push(`/contest/${data[0].id}`);
    
  };

  const changeTab = (value, progress) => {
    setValue(value);
    setProgress(progress);
  };
  const changeTabView = (event, newValue) => {
    setProgress(newValue * 20);
    setValue(newValue);
  };
  const handleSetData = (data, field) => {
    setIssueData({ ...issueData, [field]: data });
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  return (
    <Layout pageTitle="Issue Posting">
      <Grid container spacing={0} classes={{ root: "containerGrid" }}>
        <Grid item md={6} classes={{ root: "disclaimerSection" }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            classes={{
              bar1Determinate: "linearProgressBarPrimary",
              root: "linearProgressBarRoot",
            }}
          />
          <Tabs
            value={value}
            textColor="primary"
            classes={{ root: "tabs" }}
            onChange={changeTabView}
          >
            <Tab
              label="Headline"
              wrapped
              classes={{ root: "tab", textColorPrimary: "tabText" }}
              {...a11yProps(0)}
            />
            <Tab
              label="Issue Description"
              wrapped
              classes={{ root: "tab", textColorPrimary: "tabText" }}
              {...a11yProps(1)}
            />
            <Tab
              label="Legislation Needed"
              wrapped
              classes={{ root: "tab", textColorPrimary: "tabText" }}
              {...a11yProps(2)}
            />
            <Tab
              label="Location"
              wrapped
              classes={{ root: "tab", textColorPrimary: "tabText" }}
              {...a11yProps(3)}
            />
            <Tab
              label="Funding"
              wrapped
              classes={{ root: "tab", textColorPrimary: "tabText" }}
              {...a11yProps(4)}
            />
          </Tabs>
          <HeadlineDisclaimer value={value} index={0} />
          <IssueDescriptionDisclaimer value={value} index={1} />
          <LegislationDisclaimer value={value} index={2} />
          <LocationDisclaimer value={value} index={3} />
          <FundingDisclaimer value={value} index={4} />
        </Grid>
        <Grid item md={6}>
          <HeadlineForm
            value={value}
            index={0}
            changeTab={changeTab}
            handleSetData={handleSetData}
          />
          <IssueDescriptionForm
            value={value}
            index={1}
            changeTab={changeTab}
            handleSetData={handleSetData}
          />
          <LegislationForm value={value} index={2} changeTab={changeTab} />
          <LocationForm value={value} index={3} changeTab={changeTab} />
          <FundingForm
            value={value}
            index={4}
            changeTab={changeTab}
            submit={saveIssue}
            saveIssue={saveIssueWithPayment}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default PostContestPage;

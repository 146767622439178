import { createClient } from '@supabase/supabase-js'

let rootURL = window.location.host;
let supabase;
// Create a single supabase client for interacting with your database
if(rootURL === 'writelegislation.com') {
    supabase = createClient('https://skcpgmpynrhjggnoblwh.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InNrY3BnbXB5bnJoamdnbm9ibHdoIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODk4NjUzOTUsImV4cCI6MjAwNTQ0MTM5NX0.CgjqrLMYoiDtoKo6y079NVdFkWOJgJwubzK_gbMyDws');
} else {
    // This is development
    supabase = createClient('https://sfkvphnrdbgxsjbyqays.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InNma3ZwaG5yZGJneHNqYnlxYXlzIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODkyODc1NjksImV4cCI6MjAwNDg2MzU2OX0.4a3yA446jpzPcnSfm9FQ1W8HFxGCCH3eAFDOvXGxKfA');   
}

export { supabase };

import Grid from "@material-ui/core/Grid";
import { MDBCardGroup, MDBContainer } from "mdbreact";
import PropTypes from "prop-types";
import React from "react";
import ContestPanel from "./contestPanel";
import { dynamicSort } from "../../util/sort"
import { CONTEST_PAGE_URL } from '../../constants'

const ContestPanelList = ({ title, contests, size }) => {
  var contestCards = contests || [];
  if (size > -1) {
    contestCards = contests.slice(0, size);
  }
  contestCards = contestCards
    .sort(dynamicSort("-prize"))
    .map((contest, idx) => (
      <div className="col-12 col-md-4" key={contest.id}>
        <ContestPanel
          title={contest.title}
          link={CONTEST_PAGE_URL + "/" + contest.id}
          //This acts as a way to flip the color left-right color scheme every row
          left={idx % 4 === 0 || idx % 4 === 3}
          funding={contest.totalFunding}
        />
      </div>
    ));
  return (
        <div className="row px-4" style={{ padding: 0 }}>
          {contestCards}{" "}
        </div>
  );
};

export default ContestPanelList;

ContestPanelList.propTypes = {
  title: PropTypes.string,
  contests: PropTypes.array,
  size: PropTypes.number,
};

ContestPanelList.defaultProps = {
  title: "",
  contests: [],
  size: -1,
};

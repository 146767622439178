import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadUserProfile } from "../../redux/actions/UserActions";
import { Link } from "react-router-dom";
import SystemBackdrop from "../../components/widgets/backdrop/SystemBackdrop";
import {
  Divider,
  Drawer,
  Button,
  List,
  ListItem,
  Snackbar,
  Modal,
  Fab,
} from "@material-ui/core";
import Menu from "@material-ui/icons/Menu";
import Home from "@material-ui/icons/Home";
import Check from "@material-ui/icons/Check";
import Edit from "@material-ui/icons/Edit";
import Group from "@material-ui/icons/Group";
import Settings from "@material-ui/icons/Settings";
import ArrowForward from "@material-ui/icons/ArrowForward";
import { Add } from "@material-ui/icons";
import { toast } from "react-toastify";
import SortableIssuesList from "./SortableIssuesList";
import useIsMounted from "react-is-mounted-hook";
import { supabase } from "../../actions/shared/api";
import move from "lodash-move";

function ProfilePage() {
  // const [userInfo, setUserInfo] = useState('');
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const email = localStorage.getItem("email");
  const [postedIssues, setPostedIssues] = useState([]);
  const [allIssues, setAllIssues] = useState([]);
  const [postedIssueOrder, setPostedIssueOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pageShowing, setPageShowing] = useState("all");
  const isMounted = useIsMounted();
  const container = document.body;
  const [editing, setEditing] = useState(false);
  const [initialSort, setInitialSort] = useState(false);
  const [sortOrder, setSortOrder] = useState(null);
  const [newIssueModal, setNewIssueModal] = useState(localStorage.getItem("newIssueModalPop") === "True" ? false : true);
  const [newIssueTitle, setNewIssueTitle] = useState(null);
  const [newIssueDescription, setNewIssueDescription] = useState(null);
  const [newIssueLegislation, setNewIssueLegislation] = useState(null);
  const [newIssueCity, setNewIssueCity] = useState(null);
  const [newIssueState, setNewIssueState] = useState(null);
  const [userOrganization, setUserOrganization] = useState(null);
  const [issues, setIssues] = useState([]);
  const [filteredIssues, setFilteredIssues] = useState([]);
  const [currentFilter, setCurrentFilter] = useState("");
  const [isFiltered, setIsFiltered] = useState(false);


  const fetchUser = async () => {
    let postedIssues = [];
    const { data, error } = await supabase
      .from("users")
      .select("*")
      .filter("email", "eq", `${email}`);

    if (error) {
      return error;
    } else {
      if (data[0]?.organization) {
        setUserOrganization("/organization?Org=" + data[0]?.organization);
      }
      if (data[0]?.all_issues) {
        pullAllIssuesForUser(email);
      } else {
        setPostedIssues([]);
        setAllIssues([]);
      }
      setIsLoading(false);
      setEditing(true);
    }
  };

  const pullAllIssuesForUser = async (userEmail) => {
    console.log("Fetching issues for user:", userEmail); // Debugging line
    try {
      const { data, error } = await supabase
        .from("issues")
        .select("*")
        .order('order', { ascending: true })
        .filter("user", "eq", `${userEmail}`);
  
      if (error) {
        console.error('Error fetching issues:', error);
        return;
      }
  
      console.log("Fetched issues:", data); // Debugging line
  
      setAllIssues(data);
      setIssues(data);
    } catch (e) {
      console.error("An error occurred while fetching issues:", e);
    }
  };  

  const deleteIssue = async (issueID) => {
    const { data, error } = await supabase
      .from("issues")
      .delete()
      .match({ id: issueID });

    if (error) {
      alert(error);
    } else {
      toast("Issue Deleted");
      fetchUser();
    }
  };

  const postIssue = async (issueID) => {
    const { data, error } = await supabase
      .from("issues")
      .update({ status: "posted", active: "true" })
      .match({ id: issueID });

    if (error) {
      alert(error);
    } else {
      toast("Issue Posted");
      fetchUser();
    }
  };

  // Add the issue to the user table under all_issues
  const addNewIssueToUser = async (id) => {
    let issues = [
      ...allIssues,
      {
        id: id,
        title: newIssueTitle,
        description: newIssueDescription,
        legislation: newIssueLegislation,
        user: email,
        totalFunding: 200,
        currentFunding: 0,
        status: "new",
        active: "false",
      },
    ];

    // Push the new issue to the existing issues for the user
    setAllIssues(issues);

    const { data, error } = await supabase
      .from("users")
      .update({ all_issues: issues })
      .match({ email: email });

    if (error) {
      toast(error);
    } else {
      toast("Issue saved");
    }
  };

  // Save the issue to the issues database with all issues
  const saveNewIssue = async () => {
    const { data, error } = await supabase.from("issues").insert([
      {
        title: newIssueTitle,
        description: newIssueDescription,
        legislation: newIssueLegislation,
        user: email,
        endDate: "",
        totalFunding: 200,
        currentFunding: 0,
        status: "new",
        active: "false",
      },
    ]);

    // If there is an error saving the issue, show that error to the user
    if (error) {
      toast(error);
    } else {
      addNewIssueToUser(data[0].id);
      handleNewIssueModal();
    }
  };

  const handleCreateIssue = (e) => {
    e.preventDefault();
    // Add the issue to the issues table
    saveNewIssue();
  };

  const setInitialSortOrder = () => {
    let order = [];
    if (!initialSort) {
      // if the initial sort has not been set, lets check if its in the database and if it is lets set it
      order = fetchUser();
      if (order.length > 0) {
        setSortOrder(order);
      }
      setInitialSort(true);
    } else {
      setSortOrder = allIssues;
    }
  };  

  const handleNewIssueModal = () => {
    setNewIssueModal(!newIssueModal);
    localStorage.setItem('newIssueModalPop', 'True');
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleTogglePage = (e) => {
    setPageShowing(e.target.id);
  };

  const handleLogOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location = "/";
  };

  useEffect(() => {
    if (isMounted) {
      fetchUser();
      setInitialSortOrder();
    }
  }, [isMounted]);

  const filterIssues = (value) => {
    console.log("Initial issues:", issues);
    if (value === "clearAllFilters") {
        removeAllFilters();
        return;
    }
    setIsFiltered(false);
    
    let filteredIssues;
    switch (value) {
        case "ALL":
            filteredIssues = allIssues;
            break;
        case "not-archived":
            filteredIssues = allIssues.filter(issue => issue.status !== "archived");
            break;
        default:
            filteredIssues = issues.filter(issue => issue.status === value);
            break;
    }

    if(filteredIssues.length > 0) {
        setIsFiltered(true);
    }

    setFilteredIssues(filteredIssues);
    setCurrentFilter(value);
};

  const removeAllFilters = () => {
    setFilteredIssues([]);
    setIsFiltered(false);
  };

  return (
    <>
      <div
        style={{ display: "block", }}
      >
        {isLoading ? (
          <SystemBackdrop open={true} />
        ) : (
          <>
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              classes={{ modal: "mr-3" }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: 340,
                  borderRadius: "5px",
                  margin: "10px",
                },
              }}
            >
              <div className="">
                <Divider />
                <ListItem button>
                  <Home className="color-secondary" />{" "}
                  <a href="/" className="primary-link">
                    Home
                  </a>
                </ListItem>
                <Divider />
                <h4 className="pl-3 pt-4 text-white">My Issues</h4>
                <Divider />
                <List>
                  <ListItem button>
                    <Edit className="color-primary lato-font" />
                    <a
                      href="#"
                      onClick={handleTogglePage}
                      id="all"
                      className="pl-2 secondary-link text-white"
                    >
                      Private Issues
                    </a>
                  </ListItem>
                  {
                    <ListItem button>
                    <Check className="color-primary" />
                    <Link to="/legislation-list" className="pl-2 secondary-link text-white">
                      List of Legislation
                    </Link>
                  </ListItem>
                  }
                  {userOrganization ? (
                    <ListItem>
                      <Group className="color-primary" />
                      <a
                        href={userOrganization}
                        className="pl-2 secondary-link text-white"
                      >
                        Team Issues
                      </a>
                    </ListItem>
                ) : null}
                </List>
                <Divider />
                <List>
                  <ListItem button>
                    <Settings className="color-primary lato-font" />
                    <Link className="pl-2 secondary-link text-white" to="/settings">
                      Teams & Settings
                    </Link>
                  </ListItem>
                  <ListItem button>
                    <ArrowForward className="color-primary" />
                    <a
                      href="#"
                      onClick={handleLogOut}
                      className="pl-2 secondary-link text-white"
                    >
                      Log Out
                    </a>
                  </ListItem>
                </List>
              </div>
            </Drawer>
            <div
              className="p-2"
              style={{
                height: "50px",
                width: "100%",
              }}
            >
              <Button onClick={handleDrawerToggle}>
                <Menu style={{ color: "lightgray" }} />
              </Button>
            </div>
            <div className="container">
              {pageShowing === "all" ? (
                <>
                  <h1
                    className="mb-4 color-primary text-center"
                  >
                    Your Issues
                  </h1>
                    <div style={{ marginLeft: "70%" }}>
                      <select onChange={(e) => filterIssues(e.target.value)}>
                      <option value="">Filter</option>
                      <option value="not-archived">not archived</option>
                      <option value="draft">draft</option>
                      <option value="new">new</option>
                      <option value="research">research</option>
                      <option value="funding">funding</option>
                      <option value="writing">writing</option>
                      <option value="submitting">submitting</option>
                      <option value="archived">archive</option>
                      <option value="clearAllFilters">Clear Filters</option>
                      </select>
                    </div>
                  <Fab
                    className="turq-secondary-button"
                    aria-label="add"
                    onClick={handleNewIssueModal}
                  >
                    <Add />
                  </Fab>
                  <SortableIssuesList
                    issues={isFiltered ? filteredIssues : allIssues}
                    editing={editing}
                    deleteIssue={deleteIssue}
                    postIssue={postIssue}
                  />
                </>
              ) : null}
              {pageShowing === "published" ? (
                <>
                  <h1
                    className="mb-4"
                    style={{ textAlign: "center", color: "white" }}
                  >
                    Posted issues
                  </h1>
                  <SortableIssuesList
                    issues={postedIssues}
                    issueOrder={postedIssueOrder}
                    editing={false}
                    deleteIssue={deleteIssue}
                    postIssue={postIssue}
                  />
                </>
              ) : null}
            </div>
          </>
        )}
      </div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        message="Issue Posted"
      ></Snackbar>

      <Modal
        open={newIssueModal}
        onClose={handleNewIssueModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className="background-secondary"
          style={{
            maxWidth: "500px",
            margin: "20px auto",
            borderRadius: "5px",
            padding: "20px",
          }}
        >
          <h3 className="color-secondary" style={{ textAlign: "center" }}>
            Add a new issue
          </h3>
          <form>
            <div className="form-group">
              <label for="newIssueTitle" className="color-secondary">Title</label>
              <input
                type="text"
                className="form-control"
                id="newIssueTitle"
                name="newIssueTitle"
                placeholder="My amazing title"
                onChange={(e) => setNewIssueTitle(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="newIssueDescription" className="color-secondary">Description</label>
              <textarea
                className="form-control"
                id="newIssueDescription"
                name="newIssueDescription"
                rows="3"
                onChange={(e) => setNewIssueDescription(e.target.value)}
                placeholder="Describe the issue you are raising in a little more detail"
              ></textarea>
            </div>
            <div className="form-group">
              <label for="newIssueLegislation" className="color-secondary">Legislation Needed</label>
              <textarea
                className="form-control"
                id="newIssueLegislation"
                name="newIssueLegislation"
                rows="3"
                onChange={(e) => setNewIssueLegislation(e.target.value)}
                placeholder="Describe the legislation you would like to see enacted around this issue"
              ></textarea>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label for="newIssueCity" className="color-secondary">City</label>
                <input
                  type="text"
                  className="form-control"
                  id="newIssueCity"
                  name="newIssueCity"
                  onChange={(e) => setNewIssueCity(e.target.value)}
                  placeholder=""
                />
              </div>
              <div className="col">
                <label for="newIssueState" className="color-secondary">State</label>
                <input
                  type="text"
                  className="form-control"
                  id="newIssueState"
                  name="newIssueState"
                  onChange={(e) => setNewIssueState(e.target.value)}
                  placeholder=""
                />
              </div>
            </div>
            <button className="turq-secondary-button" onClick={handleCreateIssue}>
              Create
            </button>
            <button className="btn btn-danger ml-3 text-white" onClick={handleNewIssueModal}>
              Cancel
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default ProfilePage;

import React from "react"
import { Button } from "@material-ui/core"
import { Link } from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import {  EDITOR_PAGE_URL } from "../../constants"

import LegislationList from "../legislation/legislationList"
import {isPastEndDate } from "../../util/dateCompare"
import PropTypes from "prop-types";


const ContestSubmissionsCard = ({contest, legislationList}) => (
  <Card height="100%" className="background-primary">
    <CardContent>
      <h3 variant="h4" className="text-primary" component="h2">
        Legislation For This Issue
      </h3>
      <LegislationList contest={contest.id} legislation={legislationList}/>
    </CardContent>
  { /*
    <CardActions>
      <Link to={EDITOR_PAGE_URL + "/legislation?contest=" + contest.id} >
        {!isPastEndDate(contest.endDate)
          ? <button className="turq-secondary-button"> Create New Legislation</button>
          : null
        }
      </Link>
    </CardActions>
   */ }
  </Card>
)

export default ContestSubmissionsCard

ContestSubmissionsCard.propTypes = {
  contest: PropTypes.object,
  legislationList: PropTypes.array
}

ContestSubmissionsCard.defaultProps = {
  contest: null,
  legislationList: []
}
import axios from "axios";
import { toast } from "react-toastify";
import { LOGIN_URL } from "../constants";
import { supabase } from "./shared/api";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

function loginRequest() {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
  };
}

export function loginSuccess(token, email) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    token: token,
    email: email,
  };
}

function loginFailure(message) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message,
  };
}

export const login = async (creds) => {
  // We dispatch requestLogin to kickoff the call to the API
  loginRequest();

  const { user, session, error } = await supabase.auth.signIn({
    email: creds.email,
    password: creds.password,
  });

  if (error) {
    loginFailure(error);
    toast.error("Login Failed: " + error);
    return false;
  } else {
    localStorage.setItem("email", creds.email);
    loginSuccess(session.access_token, creds.email);
    return true;
  }
};

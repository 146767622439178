import React from "react"
import PropTypes, {func} from "prop-types"
import {connect, useSelector} from "react-redux"
import {Helmet} from "react-helmet";
import Grid from '@material-ui/core/Grid';

import {logout} from "../../actions/logout"
import Header from "./header"
import Footer from "./footer"
import {Redirect} from "react-router-dom";
import {withRouter} from 'react-router-dom';

const Layout = (props) => {
    const {children, pageTitle, description} = props;

    const logout = () => {
        localStorage.clear();
        window.location = '/';
    }
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    return (
        <>
            <div className="site">
                <Helmet>
                    <meta name="description" content={description}/>
                    <title>{pageTitle}</title>
                    <script src="https://io.dropinblog.com/embedjs/591bd587-9682-4a53-8063-c835f185c0fe.js"></script>
                </Helmet>
                <Grid direction="column" container className="main">
                    <Grid direction="row" item container>
                        <Header logout={logout} isAuthenticated={isAuthenticated}/>
                    </Grid>
                    <Grid direction="row" item container className="main">
                        {children}
                    </Grid>
                    <Grid direction="row" item container>
                        <Footer/>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}
export default withRouter(Layout);
Layout.propTypes = {
    children: PropTypes.node.isRequired,
    fullWidth: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    logout: PropTypes.func,
    pageTitle: PropTypes.string,
    description: PropTypes.string
}

Layout.defaultProps = {
    children: null,
    fullWidth: false,
    isAuthenticated: false,
    logout: null,
    pageTitle: "writelegislation.com",
    description: "Writelegislation.com is a social action platform for on-demand legislation creation. Real Bills, not just more petitions."
}
//
// function mapStateToProps(state) {
//   const { auth } = state
//   const { isAuthenticated } = auth
//
//   return {
//     isAuthenticated
//   }
// }
//
// const mapDispatchToProps = dispatch => {
//   return {
//     // dispatching plain actions
//     logout: () => dispatch(logout()),
//   }
// }
//
// export default connect(mapStateToProps, mapDispatchToProps)(Layout)

import React, { useEffect } from 'react';
import Layout from "../components/layout/layout";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { supabase } from "../actions/shared/api";
import Grid from "@material-ui/core/Grid";

const PaymentConfirmation = () => {
    const issueID = localStorage.getItem('postedIssue');
    const amountPaid = localStorage.getItem('postedIssuePayment');
    const history = useHistory();

    const setPaymentOnIssue = async() => {
        const { data, error } = await supabase
            .from("issues")
            .update({ currentFunding:  amountPaid * 100})
            .match({ id: issueID });
        
        if(error) {
            toast(error);
        }

        redirectToIssue();
    }

    const redirectToIssue = () => {
        history.push(`/contest/${issueID}`);
    }

    useEffect(() => {
        setTimeout(() => {
            setPaymentOnIssue();
        }, 3000)       
    }, []);

    return (
        <Layout pageTitle={`Payment Confirmation`}>
            <Grid
            container
            justify="center"
            direction="column"
            style={{ padding: 20 }}
            className="background-primary"
          >
              <h1 className="text-primary">Thanks for your payment!</h1>
              <p className="text-primary">Redirecting you to the issue...</p>
          </Grid>
        </Layout>
    )
}

export default PaymentConfirmation;
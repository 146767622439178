import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import EditorLayout from "../../components/editor/layout";
import { TextField, Grid, Modal, Button, TextareaAutosize } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { waveConfig } from "../../constants";
import { TailSpin } from "react-loading-icons";
import { toast } from 'react-toastify';


export const EditorComponents = (props) => {
  console.log(props.diffStyle);
  const [buttonColor, setButtonColor] = useState('lightgray');
  const [emailContent, setEmailContent] = useState("Your default message text here");

  function copyURLToClipboard() {
    const url = window.location.href;
    const textArea = document.createElement("textarea");
    textArea.value = url;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    toast.success("URL copied to clipboard!");
  }

async function searchRep() {
  const zipCode = document.getElementById('zipCode').value;
  const apiKey = "AIzaSyDipUgeMBmAHALX3drBqGOFa722GKetsKY";
  const url = `https://www.googleapis.com/civicinfo/v2/representatives?key=${apiKey}&address=${zipCode}`;
  
    const response = await fetch(url);
    const data = await response.json();
    console.log(data);
  
    let html = '';
    data.officials.forEach((official, index) => {
      if (official.emails && data.offices.some(office => office.officialIndices.includes(index) && office.name.includes('Representative') || office.name.includes('Senator'))) {
        html += `<p>${official.name} <button style="border-radius: 30px;" onclick="prepareEmail('${official.emails[0]}')">Copy</button></p>`;
      }
    });
  
    document.getElementById('result').innerHTML = html;
  }
  
  function prepareEmail(email) {
    const emailContent = document.getElementById('emailContent').value;
    const subject = 'Your Subject';
    const body = encodeURIComponent(emailContent);
    const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;
  
    window.location.href = mailtoLink;
  }


  return (
    <EditorLayout onSubmit={props.handleSubmit}>
      <div className="row" style={{ display: 'flex', paddingLeft: "25%",}}>
        <div className="col-sm-12 col-md-4">
            <p className="mt-4">Legislation Name</p>
          <input
            type="text"
            className="form-control mt-2"
            id="legislationName"
            placeholder="Legislation name"
            value={props.legislationName}
            onChange={props.updateLegislationName}
          ></input>
        </div>
        <div className="col-sm-12 col-md-2">
            <p className="mt-4">Add State Formattting</p>
          <select
            className="form-control mt-0"
            id="diffSelect"
            onChange={props.handleSetDiffStyle}
            value={props.diffStyle}
          >
            {props.options.map((item) => {
              return <option key={item.value} value={item.value}>{item.text} format</option>;
            })}
          </select>
        </div>
      </div>
        <div style={{ paddingLeft: "25%" }}>
          <button 
              style={{ borderRadius: "10px", fontSize: "12px", backgroundColor: buttonColor }}
              onMouseDown={() => setButtonColor('lightblue')}
              onMouseUp={() => setButtonColor('lightblue')}
              onMouseLeave={() => setButtonColor('lightblue')}
              onClick={(e) => {
                  toast.success("Please be patient and wait 45 seconds for text to appear.");
                  props.handleOpenAIRequest();
                  setTimeout(() => {
                      setButtonColor('lightgray'); 
                  }, 45000);
              }}
              >Click to Auto-Generate Legislation within 45 Seconds
        </button>
        <Button 
            variant="contained"
            color="primary"
            onClick={copyURLToClipboard}
            style={{ borderRadius: "10px", margin: '5px', padding: '5px 10px', fontSize: '0.65em' }}>
            Copy URL to Share
        </Button>
        </div>
      <p style={props.styles} className="mr-4 mb-0 pt-2">
        <CheckIcon /> Saved!
      </p>
      <div style={{ maxWidth: '50%', margin: '0 auto' }}>
      <Editor
        onInit={(evt, editor) => (props.editorRef.current = editor)}
        apiKey="ys7javpzo7hrk04t5pyqa0o5x4tpua0o9ywr8dwjnneozd0z"
        onEditorChange={(editor) => props.handleContentUpdate(editor)}
        value={props.content}
        init={{
          setup: function (editor) {
            editor.ui.registry.addMenuButton("exportPDFButton", {
              text: "Export",
              fetch: function (callback) {
                var items = [
                  {
                    type: "menuitem",
                    text: "Export PDF",
                    icon: "new-document",
                    onAction: function () {
                      props.generatePDF();
                    },
                  },
                  {
                    type: "menuitem",
                    text: "Export Word",
                    icon: "new-document",
                    onAction: function () {
                      //props.generateWord();
                    },
                  }
                ];
                callback(items);
              },
            });

            editor.ui.registry.addButton("addWhereasLine", {
              text: "Add 'Whereas' Line",
              onAction: function () {
                editor.selection.setContent(
                  `<p class="numbered" style="font-family: Times New Roman; font-weight: lighter; font-size: 12pt;">Whereas ...  </p>`);
              },
            });
            
            editor.ui.registry.addButton("addSectionLine", {
              text: "Add 'SECTION' Line",
              onAction: function () {
                editor.selection.setContent(
                  `<p class="numbered" style="font-family: Times New Roman; font-weight: lighter; font-size: 12pt;">SECTION #...  </p>`);
              },
            });
            
            editor.ui.registry.addButton("addNumberedLine", {
              text: "Add Numbered Line",
              onAction: function () {
                editor.selection.setContent(
                  `<p class="numbered" style="font-family: Times New Roman; font-weight: lighter;font-size: 12pt;"></p>`);
              },
            });
            
            editor.ui.registry.addButton("addBlankLine", {
              text: "Add Blank Line",
              onAction: function () {
                editor.selection.setContent(
                  `<p class="unnumbered" style="font-family: Times New Roman; font-weight: lighter;font-size: 12pt;"></p>`);
              },
            });  

            editor.ui.registry.addButton('diffTracking', {
              text: `Reset Changes`,
              onAction: function () {
                editor.plugins.flite.acceptAll();
                editor.plugins.flite.toggleTracking();
              }
            });

            editor.ui.registry.addButton('diffShow', {
              text: `Show Changes`,
              onAction: function () {
                editor.plugins.flite.toggleShow();
              }
            });
          },
          content_css:`/flite/css/diffStyles/${props.diffStyle}.css`,
          height: window.innerHeight,
          menubar: false,
          plugins: "flite",
          toolbar1: "diffShow diffTracking exportPDFButton",
          toolbar2: "addWhereasLine addSectionLine addNumberedLine addBlankLine",
          toolbar3: "formatselect fontselect fontsizeselect bold italic underline strikethrough alignleft aligncenter alignright alignjustify indent outdent rtl ltr bullist numlist checklist",
            autosave_interval: "20s",
          external_plugins: {
            wave: "https://cdn2.codox.io/waveTinymce/2.x/plugin.min.js",
            flite: "/flite/plugin.min.js",
          },
          wave: waveConfig(props.email),
          flite: {
            contextmenu: true,
            isTracking: true,
            isVisible: false,
            user: {
              email: props.email,
              id: props.email,
              style: props.diffStyles[props.diffStyle],
            },
          },
          content_style: `
          body { padding-left: 10%; padding-right: 10%; counter-reset: linecounter; }
          p.numbered::before { counter-increment: linecounter; content: counter(linecounter) '. '; }
          p.unnumbered::before { content: ""; counter-increment: none; }
        `,
       }}
      />
      </div>
      <Modal
        open={props.modalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            background: "white",
            textAlign: "center",
            width: "300px",
            padding: "20px",
            borderRadius: "3px",
            margin: "20px auto",
            maxWidth: "100%",
          }}
        >
          <p>Generating your PDF</p>
          <TailSpin height="3em" stroke="#3f51b5" />
        </div>
      </Modal>
        <div style={{ width: '20%', padding: "20px", position: 'fixed', top: '300px', right: '20px' }}>
            <div>
              <TextField
                id="zipCode"
                label="ZIP Code"
                variant="outlined"
                style={{ marginBottom: "10px" }}
              />
              <br />
              <Button variant="contained" color="primary" onClick={() => searchRep()}>
                Find your Lawmaker
              </Button>
              <div id="result" style= {{ padding: '10px' }}></div>
              <TextareaAutosize
                aria-label="Email Content"
                minRows={3}
                placeholder="Prepare your message here before launching your email client."
                style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
              />
              <br />
              <Button variant="contained" color="primary" onClick={() => prepareEmail('example@example.com')}>
                Lauch Email Client
              </Button>
            </div>
          </div>
    </EditorLayout>
  );
};

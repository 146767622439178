// Errors
export const TOKEN_ERROR_CODE = 401;
// Stripe
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
// Data API Links
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const CONTEST_DATA_URL = `${BASE_URL}/contest`;
export const LEGISLATION_DATA_URL = `${BASE_URL}/legislation`;
export const LOGIN_URL = `${BASE_URL}/login`;
export const GOOGLE_LOGIN_URL = `${BASE_URL}/google/login`;
export const REGISTER_URL = `${BASE_URL}/register`;
export const PAYMENTS_URL = `${BASE_URL}/payments`;
// Local Links
export const ABOUT_PAGE_URL = "/about";
export const CONTEST_PAGE_URL = "/contest";
export const POST_CONTEST_URL = `${BASE_URL}/contest`;
export const POST_CONTEST_PAGE_URL = "/post_contest";
export const LEGISLATION_PAGE_URL = "/legislation";
export const DRAFTER_PAGE_URL = "https://app.gitbook.com/@turqtim/s/turq-io/";
export const DRAFT_GUIDE_PAGE_URL =
    "https://www.ncsl.org/legislators-staff/legislative-staff/research-editorial-legal-and-committee-staff/bill-drafting-manuals.aspx";
export const SPONSOR_PAGE_URL = "/sponsor";
export const HOME_PAGE_URL = "/";
export const EDITOR_PAGE_URL = "/editor";
export const LOGIN_PAGE_URL = "/login";
export const REGISTER_PAGE_URL = "/register";
export const PROFILE_PAGE_URL = "/profile";
export const CHECKOUT_PAGE_URL = "/checkout";
export const THANKYOU_URL = `/thankyou`;
export const POST_ISSUE_URL = "/editor/contest?new";
export const SUPPORT_PAGE_URL = "/support";
export const BLOG_URL = '/blog';
export const BLOG_POST_URL = '/blog/post';

// Default Text
export const DEFAULT_CONTEST_RULES =
    "American citizens and permanent residents are eligible to draft Legislation to address this Issue..\n\nSee the terms and conditions [here](/terms/Terms_of_Service.pdf).\n\n Noncompliance with these rules or the terms and conditions may be disqualified from receiving funds for any participation.";
export const DEFAULT_CONTEST_CRITERIA = `
**Initial Screening**\n\n
All legislation written by third-party writers will be put through an initial screening to ensure compliance with terms and conditions.\n\n
**Acceptance Criteria**\n\n
Acceptance will be based on the following factors:\n\n\n\n
* **Technical Merit**\n\n  * Has the submission presented a clear understanding of the associated problems being addressed?\n  * Has the submission developed a logical and workable solution and approach to solving the problem(s)?\n  * What are the most significant aspects of this concept?\n  * Has the submission clearly described the breadth of impact of the change?\n\n\n\n
* **Originality**\n\n  * To what extent is this concept new, or in what way is this a variation of an existing idea?\n  * How is this concept unique?\n  * Does the concept use technical solutions rather than traditional approaches that often rely on enforcement?\n    * (Aim to create a law like the one that requires cars to include seatbelt reminder alerts, not like seatbelt laws that require traffic stops to enforce.)\n\n\n\n
* **Practicality**\n\n  * Who directly benefits from this concept?\n  * Can the improvements and the related activities be implemented in a practical manner?\n  * To what extent does the concept demonstrate a reasonable path for implementation?\n  * How likely is the concept to be accepted and easily used by the public sector?\n  * What are the costs anticipated to be incurred and the costs saved by executing this concept compared to the benefit to the public.\n\n
`;
export const LEGISLATION_SUBTEXT =
    "Be it enacted by the Senate and House of Representatives in General Court assembled, and by the authority of the same, as follows:";
// Editor Text
// Contest
export const LEGISLATION_TITLE = "Official Title";
export const LEGISLATION_TITLE_HINT =
    "The name of the legislation. Ex. An Act Establishing Financial Literacy Education In Massachusetts";
export const LEGISLATION_CHAPTER =
    "What Chapter of the General Laws Do You Want to Modify?";
export const LEGISLATION_CHAPTER_HINT =
    "Ex. Chapter 69 of the General Law is hereby amended by inserting the following after section 1Q the following section:";
export const LEGISLATION_SECTION = "GENERAL LAWS SECTION";
export const LEGISLATION_SECTION_HINT =
    "If you want to modify a Section of that Chapter, provide the number below. If you want to add a Section to the Chapter, give it a number below. Provide a Title for the new Section. Ex. Section 1R. Financial Literacy Education in Massachusetts";
export const LEGISLATION_ACCOMPLISHES =
    "Describe what this bill accomplishes in 1-2 sentences";
export const LEGISLATION_ACCOMPLISHES_HINT =
    "Ex. This document specifies minimum requirements for the implementation of a financial literacy education program in the public schools of the Commonwealth of Massachusetts.";
export const LEGISLATION_TERMS =
    "Define the terms you will be using in this legislation";
export const LEGISLATION_TERMS_HINT =
    "Ex. Public School - A school that is located in the Commonwealth of Massachusetts and maintained at public expense...\nPrivate School - A school that is located in the Commonwealth of Massachusetts and privately funded through parent paid tuition charges or sponsored by organizations...";
export const LEGISLATION_PURPOSE =
    "Statement of Purpose (expand and go deeper on Bill description)";
export const LEGISLATION_PURPOSE_HINT =
    "Ex. This legislation commissions the creation of an opt-in voluntary study..  allow the ongoing, long-term collection of financial health and satisfaction metrics as defined by the study authors...";
export const LEGISLATION_PROVISIONS = "Provisions";
export const LEGISLATION_PROVISIONS_HINT =
    "Ex. This legislation applies to all Massachusetts Public and Charter Schools that receive public federal funding in the Commonwealth of Massachusetts...";
export const LEGISLATION_EXCEPTIONS = "Special Exceptions";
export const LEGISLATION_EXCEPTIONS_HINT =
    "Ex. Private Schools are exempt from this legislation.Special Education curriculum and instruction are exempt from this legislation.";
export const LEGISLATION_OTHER = "Other Provisions";
export const LEGISLATION_OTHER_HINT =
    "Ex. A position on the Massachusetts State Board of Education shall be created to act as a liaison between the United States Department of Education and other State Boards of Education as it pertains to the education of financial literacy...";
// Legislation
export const CONTEST_TITLE = "Title";
export const CONTEST_TITLE_HINT = "Name of the issue";
export const CONTEST_END_DATE = "Close Date";
export const CONTEST_END_DATE_HINT = "Date the issue will close";
export const CONTEST_PRIZES = "Payment";
export const CONTEST_PRIZES_HINT =
    "Payment (USD) to the writing assistant.";
export const CONTEST_DESCRIPTION = "Description";
export const CONTEST_DESCRIPTION_HINT =
    "Description of the purpose, background, and need for this legislation";
export const CONTEST_RULES = "Rules";
export const CONTEST_RULES_HINT =
    "Caniditions that must be followed by third party legislation writers.";
export const CONTEST_CRITERIA = "Acceptance Criteria";
export const CONTEST_CRITERIA_HINT =
    "Criteria used to finalze legislation written by a writing assistant.";

// Social Media
export const TWITTER_SHARE_TEXT =
    "Hi everyone! I care about this issue on writelegislation.com a lot, but it needs more funding to get turned into legislation. Check it out and maybe add some support! ";


    // Firebase config
export const firebaseConfig = {
    apiKey: "AIzaSyCEQ7cLJuyrbRLq_A-sjXUwxseMaKVosEE",
    authDomain: "turq-legislation-editor-1.firebaseapp.com",
    databaseURL: "https://turq-legislation-editor-1-default-rtdb.firebaseio.com",
    projectId: "turq-legislation-editor-1",
    storageBucket: "turq-legislation-editor-1.appspot.com",
    messagingSenderId: "670875815290",
    appId: "1:670875815290:web:a5da8f93c91052ed44dac3",
    measurementId: "G-Y6NX5XHT68"
};

// Wave config
export const waveConfig = (email) => {
    return {
    docId    : "docId",
    user     : {
        name : email,
    },
    apiKey   : "7bdf58a1-e722-4868-bee9-b7e7c65a09b6",
}
  };


  // Stripe payment link urls
  export const stripePaymentLinks = {
      prod: {
            5: {
                link: 'https://buy.stripe.com/14kg2c2Ir01kgq4cMN'
            },
            10: {
                link: 'https://buy.stripe.com/bIYbLW2IrdSa0r6cMO'
            },
            20: {
                link: 'https://buy.stripe.com/aEUg2c0AjcO66Pu6or'
            },
            50: {
                link: 'https://buy.stripe.com/7sIeY896P3dwa1GbIM'
            },
            100: {
                link: 'https://buy.stripe.com/eVa4ju2IreWe6Pu149'
            },
            200: {
                link: 'https://buy.stripe.com/14kg2c0Aj4hA1va14a'
            }
      },
      dev: [],
  }

import React, { useState } from "react";
import { Button, Card, Chip, Fab } from "@material-ui/core";
import { Add } from "@material-ui/icons";

const OrganizationPane = (props) => {
	const [newMemberName, setNewMemberName] = useState(null);
	return (
		<>
			{props.existingOrg? (
			<Card className="background-secondary mt-4 p-4">
				<h3 className="text-gold">{props.existingOrg}</h3>
				<div className="row">
					<div className="col-10">
						<input type="text" id="orgMember" class="form-control mb-4 mt-2" placeholder="Add Members" onChange={e => setNewMemberName(e.target.value)}/>
					</div>
					<div className="col-2">
						<Fab disabled={props.addButtonDisabled} className="turq-secondary-button" aria-label="add" onClick={() => props.addMemberToOrganization(newMemberName)}>
							<Add />
						</Fab>
					</div>
				</div>
				<p className="text-white">Current Members</p>
				{props.existingOrgMembers.map((member, index) => {
					if(index === 0) {
					return (<p><Chip label={member} /></p>);
					} else {
						return (<p><Chip label={member} onDelete={() => props.removeMemberFromOrganization(member)}/></p>)
					}
				})}
				<br />
				<div className="pt-4">
					<button
						variant="contained"
						className="turq-primary-button"
						onClick={props.deleteOrganization}
					>
						Delete Team
					</button>
				</div>
			</Card>
			): (
				<Button
				variant="contained"
				onClick={props.handleSetNewOrgModal}
			>
				Create New Team
			</Button>
			)}
		</>
	);
};

export default OrganizationPane;

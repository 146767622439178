import React from "react"
import { Button, Container, Paper } from "@material-ui/core"
import { Link } from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import PropTypes from "prop-types";

import { CHECKOUT_PAGE_URL } from "../../constants";
import ItemizedFunding from "../funding/itemizedFunding";

const itemizedStyle = {
  backgroundColor: "#C4C4C4",
  color: "#7D7D7D",
  font: "Roboto 30px underline",
  margin: "auto",
  width: "95%"
};

const ItemizedFundingCard = ({ currentFunding, contestId }) => (
  <Container style={{ paddingLeft: 0, paddingRight: 0}}>
    <Card className="background-primary">
      <CardContent>
        <h3 variant="h4" className="text-primary" component="h2">
          {/*Divide by 100 to convert to decimal because we handle everything as an int*/}
          { "Current Funding: " + Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(currentFunding/100)}
        </h3>
      </CardContent>
      <CardActions>
        <Link to={CHECKOUT_PAGE_URL + "/" + contestId} >
          <button className="turq-primary-button">Support This Issue</button>
        </Link>
      </CardActions>
    </Card>
    <Paper square width="95%" elevation={0} style={itemizedStyle}>
      <ItemizedFunding total={currentFunding} />
    </Paper>
  </Container>
)

export default ItemizedFundingCard

ItemizedFundingCard.propTypes = {
  currentFunding: PropTypes.number,
  contestId: PropTypes.number
}

ItemizedFundingCard.defaultProps = {
  currentFunding: 0,
  contestId: null
}
